import { FirebaseApp } from "firebase/app";
import { Firestore, enableIndexedDbPersistence, getFirestore } from "firebase/firestore";

export class FirestoreService {
    private firestore: Firestore;

    private initializing: Promise<void>;

    public constructor(firebaseApp: FirebaseApp) {
        this.firestore = getFirestore(firebaseApp);
        this.initializing = enableIndexedDbPersistence(this.firestore);
    }

    public getFirestore = async () => {
        try {
            await this.initializing;
        } catch (e) {
            console.error(e);
        }
        return this.firestore;
    }
}
