import { FirebaseApp } from "firebase/app";
import {
    FirebaseStorage, UploadTask, getStorage, ref, uploadBytesResumable,
} from "firebase/storage";
import mime from "mime-types";

import { v4 as uuidv4 } from "uuid";
import envConfig from "../environment/environmentConfig.json";

const UPLOAD_DIRS : {[key: string] : string} = {
    "application/pdf": "pdf-uploads",
    "image/jpeg": "uploads",
    "image/png": "uploads",
};

export class FirebaseStorageService {
    private storage: FirebaseStorage;

    public constructor(firebaseApp: FirebaseApp) {
        this.storage = getStorage(firebaseApp);
    }

    public storeSheetList = (
        schemaId: string,
        fileList: FileList,
    ) => Array.from(fileList)
        .filter((file) => file !== undefined)
        .map((file) => this.storeSheet(schemaId, file))

    private storeSheet = (
        schemaId: string,
        file: File,
    ) : UploadTask | undefined => {
        const contentType = mime.lookup(file.name);
        if (!contentType || !Object.keys(UPLOAD_DIRS).includes(contentType)) {
            return undefined;
        }

        const uploadDir = `${UPLOAD_DIRS[contentType]}/${schemaId}`;

        const ext = mime.extension(contentType);
        const newFileName = `sheet_${uuidv4()}.${ext}`;
        const fileRef = ref(this.storage, `gs://${envConfig.sheetBucket}/${uploadDir}/${newFileName}`);

        const metadata = {
            contentType,
            gzip: true,
        };
        return uploadBytesResumable(fileRef, file, metadata);
    }
}
