import { Alert } from "@mui/material";
import * as React from "react";
import { useLocation } from "react-router";
import { NavUtils, Page } from "../../utils/navUtils";
import { Login } from "./login";
import { TermsOfService } from "./termsOfService";

interface ILoginWithToSProps {
    tosLink?: string;
}

export function LoginWithToS(props: ILoginWithToSProps) {
    const location = useLocation();
    const { redirectUrl } = NavUtils.getNavUrlQueryParams[Page.SignIn](location.search);

    // if tosLink is undefined, make login button visible by default
    const [isToSAccepted, setToSAccepted] = React.useState(props.tosLink === undefined);

    const styleAlignCenter: React.CSSProperties = {
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
    };

    const tosComponent = props.tosLink ? (
        <div style={styleAlignCenter}>
            <TermsOfService
                value={isToSAccepted}
                tosLink={props.tosLink}
                onChange={setToSAccepted}
            />
            <Alert severity="info" sx={{ maxWidth: "fit-content" }}>Ha már bejelentkeztél, várj egy picit, hamarosan be kell töltenie az alkalmazásnak!</Alert>
        </div>
    ) : null;

    return (
        <div>
            <div style={{ visibility: isToSAccepted ? "visible" : "hidden" }}>
                <Login redirectUrl={redirectUrl} />
            </div>
            {tosComponent}
        </div>
    );
}
