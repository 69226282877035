import * as React from "react";

// Returns the previous value of a variable (i.e. from the previous render)
export function usePrevious<T>(value: T, initialValue: T): T {
    const ref = React.useRef(initialValue);

    React.useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}
