import { Grid } from "@mui/material";
import { IActivismEvent } from "@resistance-tech/api";
import { addDays, isSameWeek, startOfWeek } from "date-fns";
import React from "react";
import { EventsDay } from "./eventsDay";
import { DATE_FN_OPTIONS } from "../../commons";

export function EventsWeek(props: {
    week: Date;
    events: IActivismEvent[];
    currentUserId: string;
}) {
    const { week, events, currentUserId } = props;
    const startOfWeekDate = startOfWeek(week, DATE_FN_OPTIONS);
    const daysThisWeek = [
        startOfWeekDate,
        addDays(startOfWeekDate, 1),
        addDays(startOfWeekDate, 2),
        addDays(startOfWeekDate, 3),
        addDays(startOfWeekDate, 4),
        addDays(startOfWeekDate, 5),
        addDays(startOfWeekDate, 6),
    ];
    const eventsThisWeek = events.filter(
        (event) => isSameWeek(event.startTime, week, DATE_FN_OPTIONS),
    );
    return (
        <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignContent="stretch" columns={7}>
            {daysThisWeek.map((day) => (
                <Grid item key={day.toISOString()} xs={7} md={1}>
                    <EventsDay events={eventsThisWeek} day={day} currentUserId={currentUserId} />
                </Grid>
            ))}
        </Grid>
    );
}
