import { Grid, Paper, Typography } from "@mui/material";
import { IActivismEvent } from "@resistance-tech/api";
import { format, isSameDay } from "date-fns";
import React from "react";
import { styled } from "@mui/material/styles";
import { EventChip } from "./eventChip";
import { DATE_FN_OPTIONS } from "../../commons";
import { START_TIME_COMPARATOR } from "./utils";

const DayContainer = styled(Paper)`
    margin: 10px;
    padding: 10px;
    width: 100%;
    height: 100%;
`;

export function EventsDay(props: {
    day: Date;
    events: IActivismEvent[];
    currentUserId: string;
}) {
    const { day, events, currentUserId } = props;
    const headerString = format(day, "MMM d", DATE_FN_OPTIONS);
    const eventsToday = events.filter((event) => isSameDay(event.startTime, day)).sort(START_TIME_COMPARATOR);
    return (
        <DayContainer>
            <Grid container spacing={1} direction="column" justifyContent="flex-start" alignContent="stretch">
                <Grid item>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>{headerString}</Typography>
                </Grid>
                {eventsToday.map((event) => (
                    <Grid item key={event.id}>
                        <EventChip event={event} currentUserId={currentUserId} />
                    </Grid>
                ))}
            </Grid>
        </DayContainer>
    );
}
