import React, { useCallback, useState } from "react";
import {
    Box, Button, Checkbox, CircularProgress,
    Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextField,
} from "@mui/material";
import { FirestoreAdminUserRole, ROLE_DISPLAY_NAMES } from "@resistance-tech/api";
import { useSnackbar } from "notistack";
import { getGlobalServices } from "../../../services/services";

interface IState {
    email: string,
    checkedRoles: FirestoreAdminUserRole[],
    sending: boolean,
    error: boolean,
    message: string
}

const initialState = {
    email: "",
    checkedRoles: [],
    sending: false,
    error: false,
    message: "",
};

export const InvitationDialog = ({ isActive, onClose } : {isActive: boolean, onClose: () => void}) => {
    const [state, setState] = useState<IState>(initialState);
    const {
        email, checkedRoles, sending, message, error,
    } = state;
    const { enqueueSnackbar } = useSnackbar();

    const handleSendClick = useCallback(async () => {
        setState({ ...state, sending: true });

        try {
            await getGlobalServices()?.functionsService.registerUsers([{ email, updatedRoles: checkedRoles }]);
            // We won't send an email to the users for now
            // await firebaseAuthService.registerEmail(email);
            enqueueSnackbar(`Sikerült hozzáadni: ${email}`, { variant: "success" });
            setState({
                ...initialState, sending: false, error: false,
            });
        } catch (err) {
            enqueueSnackbar(`Nem sikerült hozzáadni: ${email} - kérjük próbáld újra!`, { variant: "error" });
            setState({
                ...state, sending: false, error: true,
            });
        }
    }, [checkedRoles, email, state, enqueueSnackbar]);

    const handleRoleChecked = useCallback((role: FirestoreAdminUserRole) => {
        if (checkedRoles.includes(role)) {
            setState({ ...state, checkedRoles: [...checkedRoles.filter((r) => r !== role)] });
        } else {
            setState({ ...state, checkedRoles: [...checkedRoles, role] });
        }
    }, [checkedRoles, state]);

    const handleClose = useCallback(() => {
        setState(initialState);
        onClose();
    }, [onClose]);

    const renderRoleCheckbox = useCallback((role: FirestoreAdminUserRole) => {
        const roleDisplayName = ROLE_DISPLAY_NAMES[role];
        return (
            <FormControlLabel
                key={role}
                label={roleDisplayName}
                control={(
                    <Checkbox
                        name={role}
                        checked={checkedRoles.includes(role)}
                        onChange={() => handleRoleChecked(role)}
                        disabled={sending}
                    />
                )}
            />
        );
    }, [checkedRoles, handleRoleChecked, sending]);

    return (
        <Dialog open={isActive}>
            <DialogTitle>
                <Box component="span" color={error ? "red" : ""}>
                    {message}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" minWidth="20rem" maxWidth="30rem">
                    <TextField
                        className="flex-item-fill"
                        label="User Email"
                        type="email"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={email}
                        onChange={(e: any) => setState({ ...state, email: e.target.value })}
                        disabled={sending}
                    />
                    {Object.values(FirestoreAdminUserRole).map(renderRoleCheckbox)}
                </Box>

                <DialogActions>
                    { sending && (<CircularProgress />)}
                    {!sending && (
                        <>
                            <Button onClick={handleClose}>Mégse</Button>
                            <Button variant="contained" color="primary" onClick={handleSendClick}>Meghívó küldése</Button>
                        </>
                    )}
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};
