import { Avatar, Chip } from "@mui/material";
import { IActivismUserProfile } from "@resistance-tech/api";
import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { ActivismUserCard } from "./activismUserCard";

const LightTooltip = styled(({ className, ...props }: any) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 0.87)",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export function ActivismUserBadge(props: { userProfile: IActivismUserProfile; isSelected?: boolean; }) {
    const { userProfile, isSelected } = props;
    const {
        displayName, photoURL, phoneNumber,
    } = userProfile;
    const label = `${displayName ?? "(nincs név)"} ${phoneNumber ?? ""}`;
    return (
        <LightTooltip title={<ActivismUserCard userProfile={userProfile} />}>
            <Chip avatar={<Avatar alt={displayName} src={photoURL} />} label={label} color={isSelected ? "primary" : "default"} />
        </LightTooltip>
    );
}
