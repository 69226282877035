import {
    TextField, TextFieldProps,
} from "@mui/material";
import React, { useCallback, useState } from "react";

export function NumericInput(props: {
    initialValue?: number;
    onChange: (value: number | undefined) => void;
    isIntegerOnly?: boolean;
    textFieldProps?: {
        required?: TextFieldProps["required"];
        label?: TextFieldProps["label"];
        placeholder?: TextFieldProps["placeholder"];
        fullWidth?: TextFieldProps["fullWidth"];
        size?: TextFieldProps["size"];
        inputProps?: TextFieldProps["InputProps"];
    };
    validation?: {
        min?: number;
        max?: number;
    }
}) {
    const {
        initialValue, onChange, isIntegerOnly, textFieldProps, validation,
    } = props;
    const [stringValue, setStringValue] = useState<string>(initialValue?.toString() ?? "");
    const [inputError, setInputError] = useState<string | undefined>();

    const handleChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newStringValue = e.target.value;
        const newNumberValueOrNaN = isIntegerOnly ? parseInt(newStringValue, 10) : parseFloat(newStringValue.replace(/,/g, "."));
        setStringValue(newStringValue);
        const newNumberValue = Number.isNaN(newNumberValueOrNaN) ? undefined : newNumberValueOrNaN;

        // Validation
        let newInputError: string | undefined;
        if (textFieldProps?.required && newStringValue === "") {
            newInputError = "Ez a mező kötelező";
        } else if (/[0-9,.]*/.exec(newStringValue)?.[0] !== newStringValue) {
            newInputError = "Ennek a mezőnek számnak kell lennie";
        } else if (newStringValue !== "" && newNumberValue === undefined) {
            newInputError = "Ennek a mezőnek számnak kell lennie";
        } else if (validation?.min !== undefined && newNumberValue !== undefined && newNumberValue < validation.min) {
            newInputError = `Ez az érték legalább ${validation.min} kell legyen`;
        } else if (validation?.max !== undefined && newNumberValue !== undefined && newNumberValue > validation.max) {
            newInputError = `Ez az érték legfeljebb ${validation.max} kell legyen`;
        }

        setInputError(newInputError);
        if (newInputError === undefined) {
            onChange(newNumberValue);
        }
    }, [textFieldProps, validation, isIntegerOnly, onChange, setStringValue, setInputError]);

    return (
        <TextField
            required={textFieldProps?.required}
            label={textFieldProps?.label}
            placeholder={textFieldProps?.placeholder}
            size={textFieldProps?.size}
            variant="outlined"
            value={stringValue}
            onChange={handleChange}
            InputProps={{ ...(textFieldProps?.inputProps), inputMode: "numeric" }}
            error={inputError !== undefined}
            helperText={inputError}
            fullWidth={textFieldProps?.fullWidth}
        />
    );
}
