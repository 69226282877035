import {
    Dialog, DialogContent, DialogTitle,
} from "@mui/material";
import React, { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { IActivismEventCreate, IActivismEventUpdate, deserializeActivismEvent } from "@resistance-tech/api";
import { ActivismState } from "./activism.types";
import { ComponentStateContext } from "./activism.state";
import { selectCurrentUser } from "../../store/selectors";
import { EventEditor } from "./eventEditor";
import { getGlobalServices } from "../../services/services";

/** Event creator and editor dialog */
export function EventEditorDialog() {
    const { dispatch, state } = useContext(ComponentStateContext);
    const { editedEventId, createEventState } = state;
    const user = useSelector(selectCurrentUser);
    const currentUserId = user?.uid ?? "";
    const editedEvent = editedEventId === undefined
        ? undefined
        : state.events.find((event) => event.id === editedEventId);

    const handleClose = useCallback(() => {
        dispatch({ type: ActivismState.ACTIVISM_CLOSE_EVENT_CREATOR });
        dispatch({ type: ActivismState.ACTIVISM_CLOSE_EVENT_EDITOR });
    }, [dispatch]);

    const handleCreateOrUpdate = useCallback((request: {
        type: "create";
        eventRequest: IActivismEventCreate;
    } | {
        type: "update";
        eventRequest: IActivismEventUpdate;
    }) => {
        const execute = async () => {
            const globalServices = getGlobalServices();
            if (globalServices === undefined) {
                throw new Error("Failed to get global services");
            }
            const response = request.type === "create"
                ? await globalServices.functionsService.activismCreateEvents({ events: [request.eventRequest] })
                : await globalServices.functionsService.activismUpdateEvents({ events: [request.eventRequest] });
            const { events: updatedEvents } = response;
            handleClose();
            dispatch({
                type: ActivismState.ACTIVISM_ADD_EVENTS,
                payload: { events: updatedEvents.map(deserializeActivismEvent) },
            });
            if (updatedEvents.length > 0) {
                dispatch({
                    type: ActivismState.ACTIVISM_OPEN_EVENT_VIEWER,
                    payload: { eventId: updatedEvents[0].id },
                });
            }
        };
        return execute();
    }, [dispatch, handleClose]);

    const handleCreate = useCallback((eventRequest: IActivismEventCreate) => handleCreateOrUpdate({ type: "create", eventRequest }), [handleCreateOrUpdate]);

    const handleUpdate = useCallback((eventRequest: IActivismEventUpdate) => handleCreateOrUpdate({ type: "update", eventRequest }), [handleCreateOrUpdate]);

    const handleDelete = useCallback(
        () => {
            const execute = async () => {
                const globalServices = getGlobalServices();
                if (globalServices === undefined) {
                    throw new Error("Failed to get global services");
                }
                if (editedEventId === undefined) {
                    return;
                }
                await globalServices.functionsService
                    .activismDeleteEvents({ eventIds: [editedEventId] });
                handleClose();
                dispatch({
                    type: ActivismState.ACTIVISM_REMOVE_EVENTS,
                    payload: { eventIds: [editedEventId] },
                });
            };
            return execute();
        }, [dispatch, handleClose, editedEventId],
    );

    return (
        <Dialog onClose={handleClose} open={createEventState !== undefined || editedEventId !== undefined} fullWidth maxWidth="sm">
            <DialogTitle>{editedEventId === undefined ? "Új esemény" : "Esemény szerkesztése"}</DialogTitle>
            <DialogContent>
                <EventEditor
                    currentUserId={currentUserId}
                    editedEvent={editedEvent}
                    prefillData={createEventState?.defaultEventValues}
                    onClose={handleClose}
                    onCreate={handleCreate}
                    onUpdate={handleUpdate}
                    onDelete={handleDelete}
                />
            </DialogContent>
        </Dialog>
    );
}
