import * as React from "react";
import { styled } from "@mui/material/styles";
import { OAuthProvider, signInWithRedirect } from "firebase/auth";
import { Button, Grid } from "@mui/material";
import { getGlobalServices } from "../../services/services";

interface IProps {
    redirectUrl: string | undefined;
    isMomentumSsoEnabled?: boolean;
}

export function Login({ redirectUrl, isMomentumSsoEnabled }: IProps) {
    const loginRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (loginRef.current == null) {
            return;
        }

        const globalServices = getGlobalServices();

        if (globalServices !== undefined) {
            globalServices.firebaseAuthUiService.authStart(loginRef.current, redirectUrl);
        }
    }, [redirectUrl]);

    const loginWithSso = React.useCallback(() => {
        const auth = getGlobalServices()?.firebaseAuthService.getAuth();
        if (auth === undefined) {
            return;
        }
        const provider = new OAuthProvider("oidc.keycloak");
        signInWithRedirect(auth, provider)
            .catch((error) => {
                console.error("There was a problem logging in: ", error);
            });
    }, []);

    return (
        <LoginContainer>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <div ref={loginRef} />
                </Grid>
                {isMomentumSsoEnabled && (
                    <Grid item>
                        <Button onClick={loginWithSso} variant="contained" color="primary">
                            Bejelentkezés a LilaKulccsal
                        </Button>
                    </Grid>
                )}
            </Grid>
        </LoginContainer>
    );
}

// Styles
const LoginContainer = styled("div")`
    padding-top: 140px;
`;
