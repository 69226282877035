import {
    Card, CircularProgress, Grid,
} from "@mui/material";
import React, {
    useEffect, useState,
} from "react";
import { IFunctionsActivismGetGeneralStatsResponse } from "@resistance-tech/api";
import { useSnackbar } from "notistack";
import { getGlobalServices } from "../../../services/services";
import { ActivismUserStatsCard } from "./activismUserStatsCard";
import { ActivismYourNextEventCard } from "./activismYourNextEventCard";
import { ActivismOrganisationStatsCard } from "./activismOrganisationStatsCard";
import { ActivismWelcomeCard } from "./activismWelcomeCard";

export function ActivismMainBar() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<IFunctionsActivismGetGeneralStatsResponse>();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isLoading || stats !== undefined) {
            return;
        }
        const globalServices = getGlobalServices();
        if (globalServices === undefined) {
            return;
        }
        const execute = async () => {
            try {
                setIsLoading(true);
                const response = await globalServices.functionsService.activismGetGeneralStats();
                setStats(response);
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Nem sikerült betölteni a statisztikákat - kérjük próbáld újra!", { variant: "error" });
            } finally {
                setIsLoading(false);
            }
        };
        execute();
    }, [isLoading, stats, setIsLoading, setStats, enqueueSnackbar]);

    return (
        <Grid
            container
            spacing={5}
            direction="row"
            justifyItems="stretch"
            sx={{ padding: (theme) => theme.spacing(3) }}
            columns={15}
        >
            <Grid item xs={15} sm={5} lg={3}>
                <Card sx={{ padding: (theme) => theme.spacing(5), height: "100%" }}>
                    <ActivismYourNextEventCard />
                </Card>
            </Grid>
            <Grid item xs={15} sm={5} lg={3}>
                <Card sx={{ padding: (theme) => theme.spacing(5), height: "100%" }}>
                    {isLoading && (
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                    {stats !== undefined && (
                        <ActivismUserStatsCard userProfile={stats.userProfile} />
                    )}
                </Card>
            </Grid>
            <Grid item xs={15} sm={5} lg={3}>
                <Card sx={{ padding: (theme) => theme.spacing(5), height: "100%" }}>
                    {isLoading && (
                        <Grid container alignItems="center" justifyContent="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    )}
                    {stats !== undefined && (
                        <ActivismOrganisationStatsCard stats={stats} />
                    )}
                </Card>
            </Grid>
            <Grid item xs={15} sm={15} lg={6}>
                <Card sx={{ padding: (theme) => theme.spacing(5), height: "100%" }}>
                    <ActivismWelcomeCard />
                </Card>
            </Grid>
        </Grid>
    );
}
