import {
    Box,
    Typography,
} from "@mui/material";
import React from "react";

export function ActivismWelcomeCard() {
    return (
        <>
            <Box sx={{
                position: "relative", width: "100%", height: 0, paddingBottom: "56.25%",
            }}
            >
                <iframe
                    style={{
                        position: "absolute", top: 0, left: 0, width: "100%", height: "100%",
                    }}
                    src="https://www.youtube.com/embed/4u9CZOPS1n0"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            </Box>
            <Typography variant="h5" paragraph sx={{ paddingTop: (theme) => theme.spacing(5) }}>
                Sziasztok!
            </Typography>
            <Typography variant="body1" paragraph>
                A Momentum a sikereit
                {" "}
                <strong>az aktív tagságának és a lelkes aktivistáinak</strong>
                {" "}
                köszönhetően érte el mindig is. És hát ne felejtsük:
                a kormányváltás nem a karosszékekből fog eldőlni, hanem a terepen.
                {" "}
                <strong>Ezért nagyon-nagyon nagy szükség van rátok.</strong>
            </Typography>
            <Typography variant="body1" paragraph>
                Arra kérlek titeket, jelentkezzetek a momentum aktivista oldalán az eseményekre!
                Gyertek el, hiszen
                {" "}
                <strong>
                    minél több háztartásba jutunk el, minél több helyen tudunk kint lenni pultokkal
                    és találkozni az emberekkel,
                </strong>
                {" "}
                annál biztosabb, hogy a választásokon sikereket érünk el,
                és így egy
                {" "}
                <strong>emberségesebb, igazságosabb és zöldebb Magyarországon</strong>
                {" "}
                fogunk tudni végre élni!
                De ehhez ti is kelletek.
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>Úgyhogy jelentkezzetek, hozzátok a barátaitokat, gyertek, csináljuk meg közösen!</strong>
            </Typography>
            <Typography variant="body1">
                <em>Donáth Anna, a Momentum elnöke</em>
            </Typography>

            {/* <Typography variant="h5" paragraph>
                Örülünk, hogy köztünk vagy!
            </Typography>
            <Typography variant="body1" paragraph>
                A Momentumnak mint közösségi, cselekvő pártnak,
                {" "}
                <strong>a tagokban és az aktivistákban van az ereje.</strong>
                {" "}
                Így sikerült meghátrálásra kényszeríteni a Fideszt a NOlimpia idején, és így juttattuk el a
                Momentum hírét több százezer háztartásba.
            </Typography>
            <Typography variant="body1" paragraph>
                A kormányváltás sikere is jórészt a terepen dől el:
                {" "}
                <strong>minél többet vagyunk</strong>
                {" "}
                a választók között,
                minél több háztartásba juttatjuk el a kormányváltás hírét,
                {" "}
                <strong>annál nagyobb eséllyel indulhatunk neki a választásoknak.</strong>
            </Typography>
            <Typography variant="body1" paragraph>
                <strong>
                    Ezért ha teheted, jelentkezz minél több eseményre te is,
                    és hozd a barátaidat is!
                </strong>
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontWeight: "bold" }}>
                <strong>Örülünk, hogy köztünk vagy, és várunk szeretettel a terepen!</strong>
            </Typography> */}
        </>
    );
}
