import {
    Checkbox, FormControlLabel, FormGroup,
} from "@mui/material";
import * as React from "react";

interface ITermsOfServiceProps {
    onChange: (newValue: boolean) => void;
    tosLink: string;
    value: boolean;
}

export function TermsOfService(props: ITermsOfServiceProps) {
    const style = {
        padding: "0 1rem",
    };

    return (
        <div style={style}>
            <p>Az alkalmazás használatához szükségünk van a hozzájárulásodra.</p>
            <FormGroup sx={{ textAlign: "left" }}>
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={props.value}
                            onChange={(event) => {
                                props.onChange?.(event.target.checked);
                            }}
                        />
                    )}
                    label={(
                        <span>
                            Hozzájárulok, hogy a Momentum Mozgalom személyes adataimat kezelje a vonatkozó
                            {" "}
                            <a href={props.tosLink} target="_blank" rel="noreferrer"><b>adatkezelési tájékoztatóban</b></a>
                            {" "}
                            foglaltak szerint.
                        </span>
                    )}
                />
            </FormGroup>
        </div>
    );
}
