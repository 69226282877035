import * as React from "react";
import { FirestoreAdminUserRole } from "@resistance-tech/api";
import { AppHeader } from "./appHeader";
import { LoginProtector } from "./loginProtector";

interface IProps {
    title: string;
    appType: "resistance" | "activism" | "sheets" | "intra";
    /** Undefined if no authentication is required. Otherwise authentication and the provided roles are required. */
    authentication?: FirestoreAdminUserRole[];
    children: React.ReactNode;
}

PageFrame.defaultProps = {
    authentication: undefined,
};

export function PageFrame({
    title, appType, authentication, children,
}: IProps) {
    React.useEffect(() => {
        document.title = title;
    }, [title]);

    return (
        <>
            <AppHeader appType={appType} />

            {authentication ? (
                <LoginProtector requiredRoles={authentication}>
                    {children}
                </LoginProtector>
            ) : children}
        </>
    );
}
