import { createTheme } from "@mui/material";
import environmentConfig from "../environment/environmentConfig.json";

export const THEME = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: environmentConfig.primaryMainColor,
        },
        secondary: {
            main: environmentConfig.secondaryMainColor,
        },
    },
    // Bootstrap-style spacing
    spacing: (factor: number) => `${0.25 * factor}rem`,
});
