import React from "react";
import { RolesWidget } from "../rolesWidget/rolesWidget";

export function SheetAdminManager() {
    return (
        <div>
            <RolesWidget />
        </div>
    );
}
