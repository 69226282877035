import {
    Grid, Typography,
} from "@mui/material";
import React from "react";
import { IFunctionsActivismGetGeneralStatsResponse } from "@resistance-tech/api";
import EventIcon from "@mui/icons-material/Event";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import GroupsIcon from "@mui/icons-material/Groups";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ActivismStatCard } from "./activismStatCard";

export function ActivismOrganisationStatsCard(props: {
    stats: IFunctionsActivismGetGeneralStatsResponse;
}) {
    const { stats } = props;

    return (
        <>
            <Typography variant="body1" sx={{ fontWeight: "bold" }} paragraph>
                Összes eddigi aktivitásunk:
            </Typography>
            {stats !== undefined && (
                <Grid item container spacing={3} direction="column" flexWrap="nowrap" justifyContent="stretch">
                    <Grid item>
                        <ActivismStatCard
                            icon={<EventIcon fontSize="large" />}
                            value={stats.generalStats.numberOfEvents}
                            label="eseményt szerveztünk"
                        />
                    </Grid>
                    <Grid item>
                        <ActivismStatCard
                            icon={<AccessTimeIcon fontSize="large" />}
                            value={Math.round(stats.generalStats.hoursOfEvents)}
                            label="órán át"
                        />
                    </Grid>
                    <Grid item>
                        <ActivismStatCard
                            icon={<GroupsIcon fontSize="large" />}
                            value={stats.generalStats.numberOfActivists}
                            label="aktivistával"
                        />
                    </Grid>
                    <Grid item>
                        <ActivismStatCard
                            icon={<TimelapseIcon fontSize="large" />}
                            value={Math.round(stats.generalStats.hoursOfActivists)}
                            label="munkaórában"
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
}
