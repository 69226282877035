import React, {
    useCallback,
    useEffect,
    useReducer,
    useState,
} from "react";
import {
    CircularProgress, Grid, ScopedCssBaseline, Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { addWeeks, endOfWeek, startOfWeek } from "date-fns";
import { useSnackbar } from "notistack";
import { FirestoreAdminUserRole, deserializeActivismEvent } from "@resistance-tech/api";
import { LoadScript } from "@react-google-maps/api";
import { useSearchParams } from "react-router-dom";
import { ComponentStateContext, DEFAULT_STATE, reducer } from "./activism.state";
import { EventsCalendar } from "./eventsCalendar";
import { selectCurrentUser, selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { EventDetailsDialog } from "./eventDetailsDialog";
import { getGlobalServices } from "../../services/services";
import { ActivismState } from "./activism.types";
import { ActivismActionBar } from "./activismActionBar";
import { EventEditorDialog } from "./eventEditorDialog";
import { getEventWithinDistanceFilter, isEventMatchingSearchTerm } from "./utils";
import { ActivismMainBar } from "./activismMainBar/activismMainBar";
import { GOOGLE_MAPS_API_KEY } from "../../utils/general";
import { DATE_FN_OPTIONS } from "../../commons";
import { PhoneNumberWarning } from "./phoneNumberWarning";

const NOW = new Date();
const START_TIME = startOfWeek(NOW, DATE_FN_OPTIONS);
const END_TIME = endOfWeek(addWeeks(NOW, 12), DATE_FN_OPTIONS);

export function Activism() {
    const [searchParams] = useSearchParams();
    const [currentState, dispatch] = useReducer(reducer, DEFAULT_STATE);
    const { events, isLoading, filter } = currentState;
    const {
        searchTerm, onlyParticipating, onlyCoordinating, onlyOrganising, distanceKms, distanceOriginPoint,
    } = filter;
    const user = useSelector(selectCurrentUser);
    const currentUserId = user?.uid;
    const userAdminConfig = useSelector(selectCurrentUserAdminUserConfig);
    const currentUserRoles = userAdminConfig?.roles;
    const [isInitialEventFetchStarted, setIsInitialEventFetchStarted] = useState(false);
    const [isInitialUserProfileFetchStarted, setIsInitialUserProfileFetchStarted] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    // Fetch events
    useEffect(() => {
        if (isInitialEventFetchStarted) {
            // The initial fetch has already started
            return;
        }
        setIsInitialEventFetchStarted(true);
        dispatch({ type: ActivismState.ACTIVISM_SET_IS_LOADING, payload: { isLoading: true } });
        const execute = async () => {
            try {
                const getEventsResponse = await getGlobalServices()?.functionsService.activismGetEvents({
                    startTime: START_TIME.toISOString(),
                    endTime: END_TIME.toISOString(),
                });
                dispatch({
                    type: ActivismState.ACTIVISM_SET_EVENTS,
                    payload: { events: (getEventsResponse?.events ?? []).map(deserializeActivismEvent) },
                });
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Nem sikerült betölteni az eseményeket - kérjük próbáld újra!", { variant: "error" });
            } finally {
                dispatch({ type: ActivismState.ACTIVISM_SET_IS_LOADING, payload: { isLoading: false } });
                const eventId = searchParams.get("eventId");
                if (eventId) {
                    dispatch({
                        type: ActivismState.ACTIVISM_OPEN_EVENT_VIEWER,
                        payload: { eventId },
                    });
                }
            }
        };
        execute();
    }, [isLoading, isInitialEventFetchStarted, setIsInitialEventFetchStarted, enqueueSnackbar, searchParams]);

    const canonicalSearchTerms = searchTerm.trim().toLowerCase().split(" ");
    const distanceFilter = distanceOriginPoint === undefined
        ? undefined
        : getEventWithinDistanceFilter(distanceOriginPoint, distanceKms);
    const filteredEvents = events
        .filter((event) => !onlyParticipating || (event.participantUserIds ?? []).includes(currentUserId ?? ""))
        .filter((event) => !onlyCoordinating || event.coordinatorUserId === currentUserId)
        .filter((event) => !onlyOrganising || event.creatorUserId === currentUserId)
        .filter((event) => searchTerm === "" || canonicalSearchTerms.every((canonicalSearchTerm) => isEventMatchingSearchTerm(event, canonicalSearchTerm)))
        .filter((event) => distanceFilter === undefined || distanceFilter(event));

    // Fetch user profiles
    useEffect(() => {
        const currentUserRolesWithDefault = currentUserRoles ?? [];
        if (
            !currentUserRolesWithDefault.includes(FirestoreAdminUserRole.ActivismAdmin)
            && !currentUserRolesWithDefault.includes(FirestoreAdminUserRole.ActivismOrganiser)
        ) {
            return;
        }
        if (isInitialUserProfileFetchStarted) {
            // The initial fetch has already started
            return;
        }
        setIsInitialUserProfileFetchStarted(true);
        const execute = async () => {
            try {
                const [getUserProfilesResponse, getOrganiserUserProfilesResponse] = await Promise.all([
                    getGlobalServices()?.functionsService.activismGetUserProfiles(),
                    getGlobalServices()?.functionsService.activismGetOrganiserUserProfiles(),
                ]);
                dispatch({
                    type: ActivismState.ACTIVISM_SET_USER_PROFILES,
                    payload: {
                        userProfiles: (getUserProfilesResponse?.userProfiles ?? []),
                        organiserUserProfiles: (getOrganiserUserProfilesResponse?.userProfiles ?? []),
                    },
                });
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Nem sikerült betölteni az felhasználókat - kérjük próbáld újra!", { variant: "error" });
            }
        };
        execute();
    }, [isInitialUserProfileFetchStarted, currentUserRoles, setIsInitialUserProfileFetchStarted, enqueueSnackbar]);

    const handleGoogleMapsLoadError = useCallback((error: Error) => {
        console.error(error);
        enqueueSnackbar("Nem sikerült a térképek betöltése - kérjük frissítsd a lapot!");
    }, [enqueueSnackbar]);

    return (
        <ComponentStateContext.Provider value={{ state: currentState, dispatch }}>
            <ScopedCssBaseline>
                <LoadScript
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    region="HU"
                    language="hu"
                    libraries={["places"]}
                    onError={handleGoogleMapsLoadError}
                >
                    <Grid container direction="column" sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}>
                        <Grid item>
                            <ActivismMainBar />
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" sx={{ marginTop: (theme) => theme.spacing(10), marginLeft: (theme) => theme.spacing(4) }}>Következő események:</Typography>
                        </Grid>
                        <Grid item>
                            <PhoneNumberWarning />
                        </Grid>
                        <Grid item>
                            <ActivismActionBar />
                        </Grid>
                        <Grid item>
                            {isLoading ? (
                                <Grid container alignItems="center" justifyContent="center">
                                    <Grid item>
                                        <CircularProgress />
                                    </Grid>
                                </Grid>
                            ) : (
                                <EventsCalendar
                                    events={filteredEvents}
                                    startDate={START_TIME}
                                    endDate={END_TIME}
                                    currentUserId={currentUserId ?? ""}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <EventDetailsDialog />
                    <EventEditorDialog />
                </LoadScript>
            </ScopedCssBaseline>
        </ComponentStateContext.Provider>
    );
}
