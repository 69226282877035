import {
    Alert, AlertTitle, Grid,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { PhoneNumberAddButton } from "./phoneNumberAddButton";

export function PhoneNumberWarning() {
    const userAdmin = useSelector(selectCurrentUserAdminUserConfig);
    const isPhoneNumberMissing = (userAdmin?.phoneNumber ?? "") === "";

    if (!isPhoneNumberMissing) {
        return null;
    }

    return (
        <Alert severity="warning" sx={{ margin: (theme) => theme.spacing(10), padding: (theme) => theme.spacing(10) }}>
            <AlertTitle>Hiányzó telefonszám</AlertTitle>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    A részvételhez kötelező megadni a telefonszámot, mert csak így tudnak elérni a szervezők.
                    Kattints ide:
                </Grid>
                <Grid item>
                    <PhoneNumberAddButton />
                </Grid>
            </Grid>
        </Alert>
    );
}
