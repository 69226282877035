import {
    Avatar, Grid, Typography,
} from "@mui/material";
import { IActivismUserProfile } from "@resistance-tech/api";
import React from "react";
import EventIcon from "@mui/icons-material/Event";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { ActivismStatCard } from "./activismStatCard";

export function ActivismUserStatsCard(props: { userProfile: IActivismUserProfile }) {
    const { userProfile } = props;
    const {
        displayName, photoURL, stats,
    } = userProfile;

    return (
        <Grid container spacing={5} direction="column" justifyContent="stretch">
            <Grid item container spacing={5} direction="row" flexWrap="nowrap" alignItems="center">
                <Grid item flexShrink={1}>
                    <Avatar sx={{ width: 80, height: 80 }} alt={displayName} src={photoURL} />
                </Grid>
                <Grid item>
                    <Typography variant="h6">
                        {displayName ?? "(nincs név)"}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    Eddig ezzel erősítetted a csapatot:
                </Typography>
            </Grid>
            {stats && (
                <Grid item container spacing={3} direction="column" flexWrap="nowrap" justifyContent="stretch">
                    <Grid item>
                        <ActivismStatCard
                            icon={<EventIcon fontSize="large" />}
                            value={stats.numberOfEventsParticipating}
                            label="eseményen vettél részt"
                        />
                    </Grid>
                    <Grid item>
                        <ActivismStatCard
                            icon={<TimelapseIcon fontSize="large" />}
                            value={Math.round(stats.hoursOfEventsParticipating)}
                            label="órát töltöttél eseményeken"
                        />
                    </Grid>
                    <Grid item>
                        <ActivismStatCard
                            icon={<SupervisorAccountIcon fontSize="large" />}
                            value={stats.numberOfEventsCoordinating}
                            label="eseményt koordináltál"
                        />
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
