import React, { useCallback, useEffect, useState } from "react";
import { Autocomplete, GoogleMap, Marker } from "@react-google-maps/api";
import { Grid } from "@mui/material";
import "./googleMaps.css";
import { IPlace } from "./googleMaps";

const HUNGARY_CENTER = { lat: 47.1625, lng: 19.5033 };
const HUNGARY_ZOOM = 6;
const LOCATION_ZOOM = 16;

export function GoogleMapsAutocomplete(props: {
    disableInput?: boolean;
    value?: Pick<IPlace, "name" | "lat" | "lng">,
    onChange?: (place: IPlace) => void,
}) {
    const { disableInput, value, onChange } = props;
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>(undefined);
    const [center, setCenter] = useState<{ lat: number; lng: number }>(HUNGARY_CENTER);
    const [zoom, setZoom] = useState<number>(HUNGARY_ZOOM);
    const [isPlaceSet, setIsPlaceSet] = useState(false);

    const zoomToLocation = () => {
        // Two zoom changes are added to make sure that a zoom change be triggered
        setZoom(LOCATION_ZOOM - 1);
        setZoom(LOCATION_ZOOM);
    };

    const goToLocation = useCallback((coordinates: { lat: number, lng: number }) => {
        setCenter(coordinates);
        zoomToLocation();
        setIsPlaceSet(true);
    }, []);

    // If a place is provided, let's zoom to that place
    useEffect(() => {
        if (value === undefined) {
            return;
        }
        const { lat, lng } = value;
        goToLocation({ lat, lng });
    }, [value, goToLocation]);

    const handleLoad = (newAutocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(newAutocomplete);
    };

    const handlePlaceChanged = () => {
        const placeResult = autocomplete?.getPlace();
        if (placeResult === undefined) {
            return;
        }
        const {
            name, geometry, place_id: googlePlaceId, formatted_address: address,
        } = placeResult;
        const lat = geometry?.location?.lat();
        const lng = geometry?.location?.lng();
        if (googlePlaceId === undefined
            || name === undefined
            || lat === undefined
            || lng === undefined
            || address === undefined
        ) {
            return;
        }
        goToLocation({ lat, lng });
        onChange?.({
            googlePlaceId,
            name,
            address,
            lat,
            lng,
        });
    };

    return (
        <Grid container spacing={1} direction="column">
            <Grid item>
                <GoogleMap
                    mapContainerStyle={{ height: "300px", width: "100%" }}
                    zoom={zoom}
                    center={center}
                >
                    {!disableInput && (
                        <Autocomplete
                            onLoad={handleLoad}
                            onPlaceChanged={handlePlaceChanged}
                            fields={["formatted_address", "name", "geometry.location", "place_id"]}
                        >
                            <input
                                type="text"
                                placeholder="Keresés"
                                style={{
                                    boxSizing: "border-box",
                                    border: "1px solid transparent",
                                    width: "240px",
                                    height: "32px",
                                    padding: "0 12px",
                                    borderRadius: "3px",
                                    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                                    fontSize: "14px",
                                    outline: "none",
                                    textOverflow: "ellipses",
                                    position: "absolute",
                                    left: "50%",
                                    marginLeft: "-120px",
                                }}
                            />
                        </Autocomplete>
                    )}
                    {isPlaceSet && (
                        <Marker
                            position={center}
                        />
                    )}
                </GoogleMap>
            </Grid>
        </Grid>
    );
}
