import {
    SvgIcon,
    Tooltip,
} from "@mui/material";
import React from "react";

export function BadgeIcon(props: { tooltipTitle: string, IconComponent: typeof SvgIcon }) {
    const { tooltipTitle, IconComponent } = props;
    return (
        <Tooltip title={tooltipTitle}>
            <IconComponent
                sx={{
                    fontSize: "18px",
                    backgroundColor: (theme) => theme.palette.secondary.main,
                    color: (theme) => theme.palette.secondary.contrastText,
                    borderRadius: "20px",
                    padding: "3px",
                }}
            />
        </Tooltip>
    );
}
