import {
    CircularProgress,
    Grid,
    Typography,
} from "@mui/material";
import React, {
    useContext,
} from "react";
import { useSelector } from "react-redux";
import { ComponentStateContext } from "../activism.state";
import { START_TIME_COMPARATOR, isParticipatingEvent, isPastEvent } from "../utils";
import { selectCurrentUser } from "../../../store/selectors";
import { EventChip } from "../eventChip";

export function ActivismYourNextEventCard() {
    const { state } = useContext(ComponentStateContext);
    const { events, isLoading } = state;
    const currentUser = useSelector(selectCurrentUser);
    const candidateEvents = events
        .filter((event) => !isPastEvent(event))
        .filter((event) => isParticipatingEvent(event, currentUser?.uid ?? ""))
        .sort(START_TIME_COMPARATOR);
    const nextUserEvent = candidateEvents.length === 0 ? undefined : candidateEvents[0];

    return (
        <>
            <Typography variant="h5" paragraph>
                A következő eseményed:
            </Typography>
            {isLoading && (
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                </Grid>
            )}
            {!isLoading && (
                nextUserEvent !== undefined && currentUser !== undefined ? (
                    <EventChip event={nextUserEvent} currentUserId={currentUser.uid} />
                ) : (
                    <Typography variant="body1" paragraph>
                        {events.length === 0 ? "-" : "Egyelőre nincs következő eseményed."}
                    </Typography>
                )
            )}

        </>
    );
}
