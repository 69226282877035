import {
    Button,
} from "@mui/material";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { OpenUserProfileDialog } from "../../store/actions";

export function PhoneNumberAddButton() {
    const dispatchGlobal = useDispatch();

    const handleOpenUserProfileEditorClick = useCallback(() => {
        dispatchGlobal(OpenUserProfileDialog.create());
    }, [dispatchGlobal]);

    return (
        <Button
            startIcon={<PhoneAndroidIcon />}
            variant="contained"
            color="secondary"
            onClick={handleOpenUserProfileEditorClick}
        >
            Telefonszám hozzáadása
        </Button>
    );
}
