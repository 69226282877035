import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { FirestoreAdminUserRole } from "@resistance-tech/api";
import { selectCurrentUser, selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { IAppState } from "../../store/state";

interface IProps {
    requiredRoles: FirestoreAdminUserRole[];
    children: React.ReactNode;
}

function accessMessage(content: React.ReactNode) {
    return (
        <PageContent>
            <Typography variant="h4" align="center">
                {content}
            </Typography>
        </PageContent>
    );
}

export function LoginProtector({ requiredRoles, children }: IProps) {
    const isLoggedIn = useSelector((state: IAppState) => selectCurrentUser(state) !== undefined);
    const roles = useSelector((state: IAppState) => selectCurrentUserAdminUserConfig(state)?.roles);

    const loggedInContent = !requiredRoles.every((role) => roles?.includes(role))
        ? accessMessage("Nincs hozzáférésed a laphoz.")
        : <>{children}</>;

    return isLoggedIn ? loggedInContent : accessMessage("A lap megtekintéséhez be kell jelentkezned.");
}

// Styles
const PageContent = styled("div")`
    min-height: 90vh;
    padding: 10vh 20px 0 20px;
`;
