import { TypedReducer, setWith } from "redoodle";
import {
    CloseUserProfileDialog,
    OpenUserProfileDialog,
    SetCurrentUser,
    SetCurrentUserAdminUserConfig,
    SetEvents,
    SetHasPendingWrites,
    SetJobs,
    SetPosts,
    SetSenders,
    SetSurveys,
    SetTips,
} from "./actions";
import { IAppState } from "./state";

export const appReducer = TypedReducer.builder<IAppState>()
    .withHandler(SetCurrentUser.TYPE, (state, payload) => setWith(state, {
        currentUser: payload.currentUser,
    }))
    .withHandler(SetPosts.TYPE, (state, payload) => setWith(state, {
        posts: payload.posts,
    }))
    .withHandler(SetSenders.TYPE, (state, payload) => setWith(state, {
        senders: payload.senders,
    }))
    .withHandler(SetSurveys.TYPE, (state, payload) => setWith(state, {
        surveys: payload.surveys,
    }))
    .withHandler(SetEvents.TYPE, (state, payload) => setWith(state, {
        events: payload.events,
    }))
    .withHandler(SetTips.TYPE, (state, payload) => setWith(state, {
        tips: payload.tips,
    }))
    .withHandler(SetJobs.TYPE, (state, payload) => setWith(state, {
        jobs: payload.jobs,
    }))
    .withHandler(SetCurrentUserAdminUserConfig.TYPE, (state, payload) => setWith(state, {
        currentUserAdminUserConfig: payload.currentUserAdminUserConfig,
    }))
    .withHandler(SetHasPendingWrites.TYPE, (state, payload) => setWith(state, {
        hasPendingWrites: payload.hasPendingWrites,
    }))
    .withHandler(OpenUserProfileDialog.TYPE, (state, payload) => setWith(state, {
        isUserProfileDialogOpen: true,
    }))
    .withHandler(CloseUserProfileDialog.TYPE, (state, payload) => setWith(state, {
        isUserProfileDialogOpen: false,
    }))
    .build();
