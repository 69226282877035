import {
    Checkbox, FormControlLabel, Grid, InputAdornment, TextField,
} from "@mui/material";
import React, { ChangeEvent, useCallback, useContext } from "react";
import { FirestoreAdminUserRole } from "@resistance-tech/api";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import { ActivismState, IFilter } from "./activism.types";
import { ComponentStateContext } from "./activism.state";
import { selectCurrentUserAdminUserConfig } from "../../store/selectors";
import { NumericInput } from "./numericInput";
import { GoogleMapsAutocompleteInput } from "./googleMaps/googleMapsAutocompleteInput";
import { IPlace } from "./googleMaps/googleMaps";

export function ActivismFilters() {
    const { dispatch, state } = useContext(ComponentStateContext);
    const userAdmin = useSelector(selectCurrentUserAdminUserConfig);
    const roles = userAdmin?.roles ?? [];

    const { filter } = state;
    const {
        searchTerm, onlyParticipating, onlyCoordinating, onlyOrganising, distanceKms,
    } = filter;

    const setNewFilter = useCallback((newFilter: IFilter) => {
        dispatch({ type: ActivismState.ACTIVISM_SET_FILTER, payload: { filter: newFilter } });
    }, [dispatch]);

    const getCheckboxChangeHandler = useCallback(
        (key: "onlyParticipating" | "onlyCoordinating" | "onlyOrganising") => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            setNewFilter({
                ...filter,
                [key]: checked,
            });
        },
        [filter, setNewFilter],
    );

    const handleOnlyParticipatingChange = getCheckboxChangeHandler("onlyParticipating");
    const handleOnlyCoordinatingChange = getCheckboxChangeHandler("onlyCoordinating");
    const handleOnlyOrganisingChange = getCheckboxChangeHandler("onlyOrganising");

    const handleSearchTermChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setNewFilter({
            ...filter,
            searchTerm: event.target.value,
        });
    }, [filter, setNewFilter]);

    const handleDistanceOriginPointChange = useCallback((place: IPlace | undefined) => {
        setNewFilter({
            ...filter,
            distanceOriginPoint: place === undefined ? undefined : { lat: place.lat, lng: place.lng },
        });
    }, [filter, setNewFilter]);

    const handleDistanceKmsChange = useCallback((value: number | undefined) => {
        if (value === undefined) {
            return;
        }
        setNewFilter({
            ...filter,
            distanceKms: value,
        });
    }, [filter, setNewFilter]);

    return (
        <Grid container spacing={3} direction="row" alignItems="center" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Grid item>
                <TextField
                    placeholder="Szöveges keresés"
                    value={searchTerm}
                    onChange={handleSearchTermChange}
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item>
                <GoogleMapsAutocompleteInput
                    onChange={handleDistanceOriginPointChange}
                    textFieldProps={{
                        label: "A keresendő cím / környék",
                        placeholder: "Cím / környék",
                        size: "small",
                    }}
                />
            </Grid>
            <Grid item>
                <NumericInput
                    initialValue={distanceKms}
                    onChange={handleDistanceKmsChange}
                    textFieldProps={{
                        label: "Távolság",
                        size: "small",
                        inputProps: {
                            endAdornment: (
                                <InputAdornment position="end">km</InputAdornment>
                            ),
                        },
                    }}
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={(
                        <Checkbox
                            size="small"
                            checked={onlyParticipating}
                            onChange={handleOnlyParticipatingChange}
                        />
                    )}
                    label="Résztvevő vagyok"
                />
            </Grid>
            <Grid item>
                <FormControlLabel
                    control={(
                        <Checkbox
                            size="small"
                            checked={onlyCoordinating}
                            onChange={handleOnlyCoordinatingChange}
                        />
                    )}
                    label="Koordinátor vagyok"
                />
            </Grid>
            {(
                roles.includes(FirestoreAdminUserRole.ActivismOrganiser)
                || roles.includes(FirestoreAdminUserRole.ActivismAdmin)
            ) && (
                <Grid item>
                    <FormControlLabel
                        control={(
                            <Checkbox
                                size="small"
                                checked={onlyOrganising}
                                onChange={handleOnlyOrganisingChange}
                            />
                        )}
                        label="Szervező vagyok"
                    />
                </Grid>
            )}
        </Grid>
    );
}
