import "es6-shim";

import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Store } from "redux";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import huLocale from "date-fns/locale/hu/index";
import { initializeAndGetClientSideServices } from "./services/services";
import { createAppStore } from "./store/store";
import { THEME } from "./utils/themeUtils";
import { IAppState } from "./store/state";

function renderApp(AppComponent: React.ElementType, store: Store<IAppState>) {
    const WrappedApp = (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={THEME}>
                <StylesProvider injectFirst>
                    <BrowserRouter>
                        <Provider store={store as Store<any>}>
                            <CssBaseline />
                            <SnackbarProvider>
                                {/* @ts-ignore TODO(mdanka): fix type issue */}
                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={huLocale}>
                                    <AppComponent />
                                </LocalizationProvider>
                            </SnackbarProvider>
                        </Provider>
                    </BrowserRouter>
                </StylesProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
    ReactDOM.render(WrappedApp, document.getElementById("app"));
}

const importAppComponent = () : React.ElementType | undefined => {
    if (process.env.REACT_APP_TARGET === "resistance") {
        // eslint-disable-next-line global-require
        return require("./components/resistanceApp").ResistanceApp;
    }

    if (process.env.REACT_APP_TARGET === "terep") {
        // eslint-disable-next-line global-require
        return require("./components/terepApp").TerepApp;
    }

    if (process.env.REACT_APP_TARGET === "activism") {
        // eslint-disable-next-line global-require
        return require("./components/activismApp").ActivismApp;
    }

    if (process.env.REACT_APP_TARGET === "sheets") {
        // eslint-disable-next-line global-require
        return require("./components/sheetsApp").SheetsApp;
    }

    if (process.env.REACT_APP_TARGET === "intra") {
        // eslint-disable-next-line global-require
        return require("./components/intraApp").IntraApp;
    }

    return undefined;
};

let targetAppPath : string | undefined;
if (process.env.REACT_APP_TARGET === "resistance") {
    targetAppPath = "./components/resistanceApp";
}

if (process.env.REACT_APP_TARGET === "terep") {
    targetAppPath = "./components/terepApp";
}

if (process.env.REACT_APP_TARGET === "activism") {
    targetAppPath = "./components/activismApp";
}

if (process.env.REACT_APP_TARGET === "sheets") {
    targetAppPath = "./components/sheetsApp";
}

if (process.env.REACT_APP_TARGET === "intra") {
    targetAppPath = "./components/intraApp";
}

const appComponent : React.ElementType | undefined = importAppComponent();

if (appComponent && targetAppPath) {
    const store = createAppStore();
    initializeAndGetClientSideServices(store);

    renderApp(appComponent, store);
    // Hot reload
    if (module.hot) {
        module.hot.accept(targetAppPath as string, () => {
            const reloadedAppComponent = importAppComponent();
            if (reloadedAppComponent) {
                renderApp(reloadedAppComponent, store);
            }
        });
    }
} else {
    const msg = `Unknown REACT_APP_TARGET environment variable: ${process.env.REACT_APP_TARGET}`;
    renderApp((() => {
        // eslint-disable-next-line no-console
        console.log(process.env);
        return (<span>{msg}</span>);
    }), createAppStore());
}
