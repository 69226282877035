import { IActivismEvent } from "@resistance-tech/api";
import {
    add, compareAsc, format, isBefore,
} from "date-fns";
import { DATE_FN_OPTIONS } from "../../commons";

export enum EventState {
    PAST = "past",
    FULL = "full",
    PARTICIPATING = "participating",
    BOOKABLE = "bookable",
}

export const getEventState = (event: IActivismEvent, currentUserId: string): EventState => {
    if (isPastEvent(event)) {
        return EventState.PAST;
    }
    if (isParticipatingEvent(event, currentUserId)) {
        return EventState.PARTICIPATING;
    }
    if (isFullEvent(event)) {
        return EventState.FULL;
    }
    return EventState.BOOKABLE;
};

export const isPastEvent = (event: IActivismEvent) => {
    const { startTime } = event;
    return isBefore(startTime, Date.now());
};

export const isParticipatingEvent = (event: IActivismEvent, currentUserId: string) => {
    const { participantUserIds } = event;
    return (participantUserIds ?? []).includes(currentUserId);
};

export const isFullEvent = (event: IActivismEvent) => {
    const { maxCapacity, numberOfParticipants } = event;
    return maxCapacity !== undefined && numberOfParticipants >= maxCapacity;
};

export const getEventTimeRangeString = (event: IActivismEvent) => {
    const { startTime, durationMinutes } = event;
    const endTime = add(startTime, { minutes: durationMinutes });
    const timeFormatString = "HH:mm";
    const startTimeString = format(startTime, timeFormatString, DATE_FN_OPTIONS);
    const endTimeString = format(endTime, timeFormatString, DATE_FN_OPTIONS);
    return `${startTimeString}-${endTimeString}`;
};

export const getEventCapacityString = (event: IActivismEvent) => {
    const { numberOfParticipants, maxCapacity } = event;
    return `${numberOfParticipants}/${maxCapacity ?? "korlátlan"}`;
};

export const isEventMatchingSearchTerm = (
    event: IActivismEvent,
    searchTerm: string,
) => event.title.toLowerCase().includes(searchTerm.toLowerCase())
    || event.location.toLowerCase().includes(searchTerm.toLowerCase())
    || (event.address ?? "").toLowerCase().includes(searchTerm.toLowerCase())
    || (event.description ?? "").toLowerCase().includes(searchTerm.toLowerCase());

export const computeHaversineDistance = (
    point1: { lat: number; lng: number },
    point2: { lat: number; lng: number },
    unit: "km" | "miles" = "km",
) => {
    const Rmiles = 3958.8; // Radius of the Earth in miles
    const Rkm = 6371.0710; // Radius of the Earth in kms
    const R = unit === "km" ? Rkm : Rmiles;
    const rlat1 = point1.lat * (Math.PI / 180); // Convert degrees to radians
    const rlat2 = point2.lat * (Math.PI / 180); // Convert degrees to radians
    const difflat = rlat2 - rlat1; // Radian difference (latitudes)
    const difflon = (point2.lng - point1.lng) * (Math.PI / 180); // Radian difference (longitudes)
    const d = (2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2))
        + (Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2))));
    return d;
};

export const getEventWithinDistanceFilter = (
    originPoint: { lat: number; lng: number },
    maxDistanceKms: number,
) => (event: IActivismEvent) => {
    if (event.lat === undefined || event.lng === undefined) {
        return false;
    }
    const eventPoint = { lat: event.lat, lng: event.lng };
    const distanceKms = computeHaversineDistance(originPoint, eventPoint);
    return distanceKms <= maxDistanceKms;
};

export const START_TIME_COMPARATOR = (
    event1: IActivismEvent,
    event2: IActivismEvent,
) => compareAsc(event1.startTime, event2.startTime);
