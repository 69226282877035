import { Grid } from "@mui/material";
import { IActivismEvent } from "@resistance-tech/api";
import {
    addWeeks, differenceInWeeks, endOfWeek, isAfter, isBefore, startOfWeek,
} from "date-fns";
import React from "react";
import { EventsWeek } from "./eventsWeek";
import { DATE_FN_OPTIONS } from "../../commons";

export function EventsCalendar(props: {
    startDate: Date;
    endDate: Date;
    events: IActivismEvent[];
    currentUserId: string;
}) {
    const {
        startDate, endDate, events, currentUserId,
    } = props;
    const startOfWeekStartDate = startOfWeek(startDate, DATE_FN_OPTIONS);
    const endOfWeekEndDate = endOfWeek(endDate, DATE_FN_OPTIONS);
    const eventsWithinRange = events.filter(
        (event) => isAfter(event.startTime, startOfWeekStartDate) && isBefore(event.startTime, endOfWeekEndDate),
    );
    const numberOfWeeksInRange = differenceInWeeks(endOfWeekEndDate, startOfWeekStartDate);
    const weekStartDates = [];
    for (let weekIndex = 0; weekIndex < numberOfWeeksInRange; weekIndex++) {
        weekStartDates.push(addWeeks(startOfWeekStartDate, weekIndex));
    }
    return (
        <Grid container spacing={3} direction="column" alignContent="stretch">
            {weekStartDates.map((weekStartDate) => (
                <Grid item key={weekStartDate.toISOString()}>
                    <EventsWeek events={eventsWithinRange} week={weekStartDate} currentUserId={currentUserId} />
                </Grid>
            ))}
        </Grid>
    );
}
