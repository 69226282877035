import * as React from "react";
import { styled } from "@mui/material/styles";
import DocumentTitle from "react-document-title";
import { useLocation } from "react-router";
import { Navigate, Route, Routes } from "react-router-dom";
import { FirestoreAdminUserRole } from "@resistance-tech/api";
import { NavUtils, Page } from "../utils/navUtils";
import { ScrollToTop } from "./common/scrollToTop";
import { PageFrame } from "./general/pageFrame";
import { StaticContent } from "./general/staticContent";
import { SheetAdminManager } from "./sheets/sheetAdmin/sheetAdminManager";
import { Activism } from "./activism/activism";
import { getGlobalServices } from "../services/services";
import { LoginWithToS } from "./general/loginWithToS";

const APP_TYPE = "activism" as const;

export function ActivismApp() {
    const location = useLocation();

    const tosLink = "/aktivista_adatkezelesi_20220120.pdf";

    /** Log screen views when the path changes */
    React.useEffect(() => {
        getGlobalServices()?.firebaseAnalyticsService.logPageView();
    }, [location.pathname]);

    return (
        <DocumentTitle title={NavUtils.getNavUrlSimpleTitle[Page.Home]}>
            <ScrollToTop>
                <AppContainer>
                    <AppContent>
                        <Routes>
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.Home]}
                                element={<Navigate to={NavUtils.getNavUrl[Page.ActivismBooking]()} />}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.SignIn]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.SignIn]}
                                        appType={APP_TYPE}
                                    >
                                        <LoginWithToS tosLink={tosLink} />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.TermsOfService]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.TermsOfService]}
                                        appType={APP_TYPE}
                                    >
                                        Az adatkezelési tájékoztató megtekinthető
                                        {" "}
                                        <a href={tosLink}>itt</a>
                                        .
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.PrivacyPolicy]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.PrivacyPolicy]}
                                        appType={APP_TYPE}
                                    >
                                        <StaticContent type="privacy policy" />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.ActivismBooking]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.ActivismBooking]}
                                        authentication={[]}
                                        appType={APP_TYPE}
                                    >
                                        <Activism />
                                    </PageFrame>
                                )}
                            />
                            <Route
                                path={NavUtils.getNavUrlTemplate[Page.Admin]}
                                element={(
                                    <PageFrame
                                        title={NavUtils.getNavUrlSimpleTitle[Page.Admin]}
                                        authentication={[FirestoreAdminUserRole.Admin]}
                                        appType={APP_TYPE}
                                    >
                                        <SheetAdminManager />
                                    </PageFrame>
                                )}
                            />

                            <Route element={<Navigate to={NavUtils.getNavUrl[Page.Home]()} />} />
                        </Routes>
                    </AppContent>
                </AppContainer>
            </ScrollToTop>
        </DocumentTitle>
    );
}

// Styles
const AppContainer = styled("div")`
    display: flex;
    flex-direction: column;
`;

const AppContent = styled("div")`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
`;
