import { TypedAction } from "redoodle";
import {
    IFirestoreAdminUserConfig,
} from "@resistance-tech/api";
import { IUser } from "../commons";
import {
    IEventsState,
    IJobsState,
    IPostsState,
    ISendersState,
    ISurveysState,
    ITipsState,
} from "./state";

export const SetCurrentUser = TypedAction.define("RESISTANCE//SET_CURRENT_USER")<{
    currentUser: IUser | undefined;
}>();

export const SetPosts = TypedAction.define("RESISTANCE//SET_POSTS")<{
    posts: IPostsState;
}>();

export const SetSenders = TypedAction.define("RESISTANCE//SET_SENDERS")<{
    senders: ISendersState;
}>();

export const SetJobs = TypedAction.define("RESISTANCE//SET_JOBS")<{
    jobs: IJobsState;
}>();

export const SetSurveys = TypedAction.define("RESISTANCE//SET_SURVEYS")<{
    surveys: ISurveysState;
}>();

export const SetEvents = TypedAction.define("RESISTANCE//SET_EVENTS")<{
    events: IEventsState;
}>();

export const SetTips = TypedAction.define("RESISTANCE//SET_TIPS")<{
    tips: ITipsState
}>();

export const SetCurrentUserAdminUserConfig = TypedAction.define("RESISTANCE//SET_CURRENT_USER_ADMIN_USER_CONFIG")<{
    currentUserAdminUserConfig: IFirestoreAdminUserConfig | undefined;
}>();

export const SetHasPendingWrites = TypedAction.define("RESISTANCE//SET_HAS_PENDING_WRITES")<{
    hasPendingWrites: boolean;
}>();

export const OpenUserProfileDialog = TypedAction.define("RESISTANCE//OPEN_USER_PROFILE_DIALOG")<void>();

export const CloseUserProfileDialog = TypedAction.define("RESISTANCE//CLOSE_USER_PROFILE_DIALOG")<void>();
