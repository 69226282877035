import {
    CircularProgress,
    Grid,
    IconButton,
} from "@mui/material";
import {
    FirestoreAdminUserRole, IActivismEvent, IActivismUserProfile, deserializeActivismEvent,
} from "@resistance-tech/api";
import React, { useCallback, useContext, useState } from "react";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import { ActivismUserBadge } from "./activismUserBadge";
import { getGlobalServices } from "../../services/services";
import { ActivismUserPicker } from "./activismUserPicker";
import { ComponentStateContext } from "./activism.state";
import { ActivismState } from "./activism.types";

export function EventDetailsParticipants(props: {
    event: IActivismEvent;
    currentUserId: string;
    currentUserRoles: string[];
}) {
    const { dispatch } = useContext(ComponentStateContext);
    const {
        event, currentUserId, currentUserRoles,
    } = props;
    const {
        id,
        maxCapacity,
        numberOfParticipants,
        participantUserProfiles,
    } = event;
    const canEditParticipants = event.creatorUserId === currentUserId
        || event.coordinatorUserId === currentUserId
        || currentUserRoles.includes(FirestoreAdminUserRole.ActivismAdmin);
    const [isWorking, setIsWorking] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const knownNumberOfParticipants = (participantUserProfiles ?? []).length;

    const handleWorkingClick = useCallback(async (callback: () => Promise<void>, successMessage: string) => {
        try {
            setIsWorking(true);
            await callback();
            enqueueSnackbar(successMessage, { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Hiba történt: kérjük próbáld újra!", { variant: "error" });
        } finally {
            setIsWorking(false);
        }
    }, [enqueueSnackbar]);

    const getAddOrRemoveHandler = useCallback(
        (mode: "add" | "remove", participantUserId: string | null) => () => handleWorkingClick(
            async () => {
                const globalServices = getGlobalServices();
                if (globalServices === undefined || participantUserId == null) {
                    return;
                }
                const { event: updatedEvent } = mode === "add"
                    ? await globalServices.functionsService.activismAddParticipant({
                        eventId: id,
                        participantUserId,
                    })
                    : await globalServices.functionsService.activismRemoveParticipant({
                        eventId: id,
                        participantUserId,
                    });
                dispatch({
                    type: ActivismState.ACTIVISM_ADD_EVENTS,
                    payload: { events: [deserializeActivismEvent(updatedEvent)] },
                });
            },
            mode === "add" ? "Résztvevő hozzáadva" : "Résztvevő törölve",
        ),
        [id, handleWorkingClick, dispatch],
    );

    const handleAdd = (participantUserId: string | null) => getAddOrRemoveHandler("add", participantUserId)();

    const renderActivistRow = useCallback((participantUserProfile: IActivismUserProfile) => (
        <Grid item key={participantUserProfile.id}>
            <ActivismUserBadge userProfile={participantUserProfile} />
            {canEditParticipants && (
                <IconButton onClick={getAddOrRemoveHandler("remove", participantUserProfile.id)} size="small" disabled={isWorking}>
                    <DeleteIcon fontSize="inherit" />
                </IconButton>
            )}
        </Grid>
    ), [canEditParticipants, isWorking, getAddOrRemoveHandler]);

    return (
        <>
            <strong>Résztvevők:</strong>
            {" "}
            {numberOfParticipants === 0 && "még senki sem jelentkezett"}
            <Grid container direction="column">
                {isWorking && (
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                )}
                {participantUserProfiles?.map(renderActivistRow)}
                <Grid item>
                    {canEditParticipants && (
                        <ActivismUserPicker
                            userId={null}
                            onChange={handleAdd}
                            inputProps={{
                                label: "Új résztvevő hozzáadása",
                                disabled: isWorking
                                    || (maxCapacity != null && numberOfParticipants >= maxCapacity),
                                size: "small",
                            }}
                        />
                    )}
                </Grid>
            </Grid>
            {knownNumberOfParticipants === 0 && numberOfParticipants !== 0 && `${numberOfParticipants} résztvevő`}
            {knownNumberOfParticipants !== 0 && numberOfParticipants > knownNumberOfParticipants && ` és további ${numberOfParticipants - knownNumberOfParticipants} résztvevő`}
        </>
    );
}
