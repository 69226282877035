import {
    DocumentData,
    DocumentSnapshot,
    Firestore,
    QuerySnapshot,
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    setDoc,
    updateDoc,
} from "firebase/firestore";
import { User } from "firebase/auth";
import { Store } from "redoodle";
import {
    CollectionId,
    IFirestoreAdminUserConfig,
    IFirestoreEvent,
    IFirestoreEventCreate,
    IFirestoreEventUpdate,
    IFirestorePost,
    IFirestorePostCreate,
    IFirestorePostUpdate,
    IFirestoreSender,
    IFirestoreSurvey,
    IFirestoreSurveyCreate, IFirestoreSurveyResponse,
    IFirestoreSurveyUpdate,
    IFirestoreTip,
    IFirestoreTipCreate,
    IFirestoreTipUpdate,
    IFormSchema,
    IJobConfig,
    ISheet,
} from "@resistance-tech/api";
import {
    SetCurrentUserAdminUserConfig,
    SetEvents,
    SetHasPendingWrites,
    SetJobs,
    SetPosts,
    SetSenders,
    SetSurveys,
    SetTips,
} from "../store/actions";
import { IAppState } from "../store/state";
import { FirebaseAuthService } from "./firebaseAuthService";
import { FirestoreService } from "./firestoreService";

const converter = <T>() => ({
    toFirestore(data: T): DocumentData {
        return data as DocumentData;
    },
    fromFirestore(snapshot: DocumentSnapshot): T {
        return snapshot.data() as T;
    },
});

const querySnapshotToObjects = <API>(querySnapshot: QuerySnapshot<API>) => {
    const objects: { [id: string]: API } = {};
    querySnapshot.forEach((docRef) => {
        objects[docRef.id] = docRef.data({ serverTimestamps: "estimate" });
    });
    return objects;
};

const documentSnapshotToObject = <API>(documentSnapshot: DocumentSnapshot) => documentSnapshot.data({ serverTimestamps: "estimate" }) as API;

export class DataService {
    private snapshotUnsubscribers: Array<() => void> = [];

    private firestore: Promise<Firestore>;

    private hasPendingWritesMap: { [key: string]: boolean } = {
        [CollectionId.MobilePosts]: false,
        [CollectionId.Jobs]: false,
    };

    public constructor(
        firestoreService: FirestoreService,
        private firebaseAuthService: FirebaseAuthService,
        private store: Store<IAppState> | undefined,
    ) {
        this.firestore = firestoreService.getFirestore();
        const currentUser = this.firebaseAuthService.authGetCurrentUser();
        this.subscribeToDataStoreIfLoggedIn(currentUser);
        firebaseAuthService.subscribeToAuthState(this.subscribeToDataStoreIfLoggedIn);
    }

    private subscribeToDataStoreIfLoggedIn = (currentUser: User | undefined | null) => {
        if (currentUser != null) {
            this.subscribeToDataStore(currentUser);
        }
    };

    public subscribeToDataStore = async (currentUser: User) => {
        if (this.store === undefined) {
            return;
        }
        const { store } = this;
        // Unsubscribe previous listeners
        this.snapshotUnsubscribers.forEach((unsubscriber) => unsubscriber());
        this.snapshotUnsubscribers.splice(0, this.snapshotUnsubscribers.length);
        // Subscribe new listeners
        this.snapshotUnsubscribers.push(
            await this.subscribeToCollection<IFirestorePost>(
                CollectionId.MobilePosts,
                (documents, hasPendingWrites) => {
                    store.dispatch(SetPosts.create({ posts: documents }));
                    this.setPendingWrite(CollectionId.MobilePosts, hasPendingWrites);
                },
            ),
        );
        this.snapshotUnsubscribers.push(
            await this.subscribeToCollection<IFirestoreSurvey>(
                CollectionId.MobileSurveys,
                (documents, hasPendingWrites) => {
                    store.dispatch(SetSurveys.create({ surveys: documents }));
                    this.setPendingWrite(CollectionId.MobileSurveys, hasPendingWrites);
                },
            ),
        );

        this.snapshotUnsubscribers.push(
            await this.subscribeToCollection<IFirestoreEvent>(
                CollectionId.MobileEvents,
                (documents, hasPendingWrites) => {
                    store.dispatch(SetEvents.create({ events: documents }));
                    this.setPendingWrite(CollectionId.MobileEvents, hasPendingWrites);
                },
            ),
        );

        this.snapshotUnsubscribers.push(
            await this.subscribeToCollection<IFirestoreTip>(
                CollectionId.MobileTips,
                (documents, hasPendingWrites) => {
                    store.dispatch(SetTips.create({ tips: documents }));
                    this.setPendingWrite(CollectionId.MobileTips, hasPendingWrites);
                },
            ),
        );

        this.snapshotUnsubscribers.push(
            await this.subscribeToCollection<IJobConfig>(
                CollectionId.Jobs,
                (documents, hasPendingWrites) => {
                    store.dispatch(SetJobs.create({ jobs: documents }));
                    this.setPendingWrite(CollectionId.Jobs, hasPendingWrites);
                },
            ),
        );
        this.snapshotUnsubscribers.push(
            await this.subscribeToCollection<IFirestoreSender>(
                CollectionId.MobileSenders,
                (documents, hasPendingWrites) => {
                    store.dispatch(SetSenders.create({ senders: documents }));
                    this.setPendingWrite(CollectionId.MobileSenders, hasPendingWrites);
                },
            ),
        );
        this.snapshotUnsubscribers.push(
            await this.subscribeToDocument<IFirestoreAdminUserConfig>(
                CollectionId.AdminUserConfigs,
                currentUser.uid,
                (document) => {
                    store.dispatch(SetCurrentUserAdminUserConfig.create({ currentUserAdminUserConfig: document }));
                },
            ),
        );
    };

    public subscribeToCollection = async <API>(
        collectionName: string,
        onUpdate: (documents: { [id: string]: API }, hasPendingWrites: boolean) => void,
    ) => {
        const currentUser = this.firebaseAuthService.authGetCurrentUser();
        if (currentUser == null) {
            throw new Error(`Cannot subscribe to collection ${collectionName} if user is not logged in.`);
        }

        return onSnapshot(
            query(collection(await this.firestore, collectionName).withConverter(converter<API>())),
            { includeMetadataChanges: true },
            (querySnapshot) => {
                const documents = querySnapshotToObjects<API>(querySnapshot);
                const hasPendingWrites = querySnapshot.docs.some((docRef) => docRef.metadata.hasPendingWrites);
                onUpdate(documents, hasPendingWrites);
            },
        );
    };

    public subscribeToDocument = async <API>(
        collectionName: string,
        docId: string,
        onUpdate: (document: API, hasPendingWrites: boolean) => void,
    ) => {
        const currentUser = this.firebaseAuthService.authGetCurrentUser();
        if (currentUser == null) {
            throw new Error(`Cannot subscribe to document ${docId} in collection ${collectionName} if user is not logged in.`);
        }

        const docRef = doc(await this.firestore, collectionName, docId).withConverter(converter<API>());
        return onSnapshot(
            docRef,
            { includeMetadataChanges: true },
            (documentSnapshot) => {
                if (documentSnapshot.exists()) {
                    const document = documentSnapshotToObject<API>(documentSnapshot);
                    const { hasPendingWrites } = documentSnapshot.metadata;
                    onUpdate(document, hasPendingWrites);
                }
            },
        );
    };

    public getAllDocuments = async <API>(collectionName: string) => {
        try {
            const querySnapshot = await getDocs(query(collection(
                await this.firestore,
                collectionName,
            ).withConverter(converter<API>())));
            return querySnapshotToObjects<API>(querySnapshot);
        } catch (error) {
            console.error(`[DataService] Failed to get all ${collectionName} IDs. ${(error as Error).toString()}`);
            return {} as { [id: string]: API };
        }
    };

    public getAllDocumentIds = async (collectionName: string) : Promise<string[]> => {
        try {
            const querySnapshot = await getDocs(query(collection(
                await this.firestore,
                collectionName,
            )));
            return querySnapshot.docs.map((docRef) => docRef.id);
        } catch (error) {
            console.error(`[DataService] Failed to get all ${collection} IDs. ${(error as any).message}`);
        }
        return [];
    };

    // Posts
    public createPost = async (newPost: IFirestorePostCreate) => addDoc(
        collection(await this.firestore, CollectionId.MobilePosts),
        newPost,
    ).catch((reason: Error) => console.error(`[DataService] Failed to create post. ${reason.message}`));

    public updatePost = async (id: string, update: IFirestorePostUpdate) => updateDoc(
        doc(await this.firestore, CollectionId.MobilePosts, id),
        update,
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to update post with id ${id}. ${reason.message}`,
    ));

    public deletePost = async (id: string) => deleteDoc(
        doc(await this.firestore, CollectionId.MobilePosts, id),
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to delete post with id ${id}. ${reason.message}`,
    ));

    // Surveys
    public createSurvey = async (newSurvey: IFirestoreSurveyCreate) => addDoc(
        collection(await this.firestore, CollectionId.MobileSurveys),
        newSurvey,
    ).catch((reason: Error) => console.error(`[DataService] Failed to create survey. ${reason.message}`));

    public updateSurvey = async (id: string, update: IFirestoreSurveyUpdate) => updateDoc(
        doc(await this.firestore, CollectionId.MobileSurveys, id),
        update,
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to update survey with id ${id}. ${reason.message}`,
    ));

    public deleteSurvey = async (id: string) => deleteDoc(
        doc(await this.firestore, CollectionId.MobileSurveys, id),
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to delete survey with id ${id}. ${reason.message}`,
    ));

    // Survey answers
    public getSurveyAnswers = async (id: string) => getDocs(query(
        collection(
            await this.firestore,
            CollectionId.MobileSurveys,
            id,
            CollectionId.MobileSurveysResponses,
        ).withConverter(converter<IFirestoreSurveyResponse>()),
    )).then((querySnapshot) => querySnapshotToObjects<IFirestoreSurveyResponse>(querySnapshot));

    // Events
    public createEvent = async (newEvent: IFirestoreEventCreate) => addDoc(
        collection(await this.firestore, CollectionId.MobileEvents),
        newEvent,
    ).catch((reason: Error) => console.error(`[DataService] Failed to create event. ${reason.message}`));

    public updateEvent = async (id: string, update: IFirestoreEventUpdate) => updateDoc(
        doc(await this.firestore, CollectionId.MobileEvents, id),
        update,
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to update event with id ${id}. ${reason.message}`,
    ));

    public deleteEvent = async (id: string) => deleteDoc(
        doc(await this.firestore, CollectionId.MobileEvents, id),
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to delete event with id ${id}. ${reason.message}`,
    ));

    // Tips
    public createTip = async (newTip: IFirestoreTipCreate) => addDoc(
        collection(await this.firestore, CollectionId.MobileTips),
        newTip,
    ).catch((reason: Error) => console.error(`[DataService] Failed to create tip. ${reason.message}`));

    public updateTip = async (id: string, update: IFirestoreTipUpdate) => updateDoc(
        doc(await this.firestore, CollectionId.MobileTips, id),
        update,
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to update tip with id ${id}. ${reason.message}`,
    ));

    public deleteTip = async (id: string) => deleteDoc(
        doc(await this.firestore, CollectionId.MobileTips, id),
    ).catch((reason: Error) => console.error(
        `[DataService] Failed to delete tip with id ${id}. ${reason.message}`,
    ));

    // Jobs
    public createJob = async (newData: IJobConfig) => addDoc(
        collection(await this.firestore, CollectionId.Jobs),
        newData,
    ).catch((reason: Error) => console.error(`[DataService] Failed to create job config. ${reason.message}`));

    // Sheets
    public findSheetSchema = async (sheetDocumentId: string) => getDoc(
        doc(
            await this.firestore,
            CollectionId.SheetsSheets,
            sheetDocumentId,
        ).withConverter(converter<IFormSchema>()),
    ).then((sheetDocument) => {
        const sheet = documentSnapshotToObject<ISheet>(sheetDocument);
        return this.selectSchema(sheet.formSchemaId);
    })

    public selectSchema = async (schemaId: string) : Promise<IFormSchema | undefined> => {
        const schemaSnapshot = await getDoc(
            doc(
                await this.firestore,
                CollectionId.SheetsSchemas,
                schemaId,
            ).withConverter(converter<IFormSchema>()),
        );
        const schema = schemaSnapshot.data();
        if (schema) {
            schema.name = schemaId;
        }
        return schema;
    };

    public getSchemas = async () : Promise<IFormSchema[]> => {
        const snapshot = await getDocs<IFormSchema>(
            collection(await this.firestore, CollectionId.SheetsSchemas)
                .withConverter(converter<IFormSchema>()),
        );
        return snapshot.docs.map((docRef) => {
            const schema = docRef.data();
            schema.name = docRef.id;
            return schema;
        });
    };

    public createSchema = async (
        name: string,
        newSchema: IFormSchema,
    ) => setDoc(doc(await this.firestore, CollectionId.SheetsSchemas, name), newSchema)
        .catch((reason: Error) => console.error(`[DataService] Failed to create schema. ${reason.message}`));

    public getSheetPreferences = async () => {
        const currentUser = this.firebaseAuthService.authGetCurrentUser();
        if (!currentUser) {
            return Promise.reject();
        }

        const docRef = await getDoc(
            doc<IFirestoreAdminUserConfig>(
                collection(await this.firestore, CollectionId.AdminUserConfigs)
                    .withConverter(converter<IFirestoreAdminUserConfig>()), currentUser.uid,
            ),
        );
        return docRef.data();
    };

    private setPendingWrite = (key: string, value: boolean) => {
        this.hasPendingWritesMap[key] = value;
        const hasPendingWrites = Object.values(this.hasPendingWritesMap).includes(true);
        const { store } = this;
        if (store === undefined) {
            return;
        }
        store.dispatch(SetHasPendingWrites.create({ hasPendingWrites }));
    };
}
