import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { getGlobalServices } from "../../services/services";
import { selectCurrentUser, selectCurrentUserAdminUserConfig, selectIsUserProfileDialogOpen } from "../../store/selectors";
import { IAppState } from "../../store/state";
import { CloseUserProfileDialog } from "../../store/actions";

export function UserProfileEditor() {
    const currentUser = useSelector((state: IAppState) => selectCurrentUser(state));
    const currentUserId = currentUser?.uid;
    const currentUserAdminUserConfig = useSelector((state: IAppState) => selectCurrentUserAdminUserConfig(state));
    const { enqueueSnackbar } = useSnackbar();
    const [newPhoneNumber, setNewPhoneNumber] = useState<string>("");
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const isUserProfileDialogOpen = useSelector((state: IAppState) => selectIsUserProfileDialogOpen(state));
    const dispatch = useDispatch();

    const photoUrl = currentUser?.photoURL ?? "";
    const displayName = currentUser?.displayName ?? "";
    const email = currentUser?.email ?? "";
    const phoneNumber = currentUserAdminUserConfig?.phoneNumber ?? "";

    useEffect(() => {
        setNewPhoneNumber(phoneNumber);
    }, [phoneNumber]);

    const handlePhoneNumberChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPhoneNumber(e.target.value);
    }, [setNewPhoneNumber]);

    const handleClose = useCallback(() => {
        dispatch(CloseUserProfileDialog.create());
    }, [dispatch]);

    const handleSaveClick = useCallback(() => {
        const execute = async () => {
            const globalServices = getGlobalServices();
            if (globalServices === undefined || currentUserId === undefined) {
                return;
            }
            try {
                setIsSaving(true);
                await globalServices.functionsService.activismUpdateUserProfile({
                    userId: currentUserId,
                    phoneNumber: newPhoneNumber,
                });
                enqueueSnackbar("Sikerült az adataid mentése!", { variant: "success" });
            } catch (e) {
                console.error(e);
                enqueueSnackbar("Nem sikerült a mentés - kérjük próbáld újra!", { variant: "error" });
            } finally {
                setIsSaving(false);
            }
        };
        execute();
    }, [currentUserId, enqueueSnackbar, newPhoneNumber, setIsSaving]);

    const avatarStyle = { width: 120, height: 120 };
    let avatar;
    if (photoUrl || (!photoUrl && !displayName)) {
        avatar = <Avatar sx={avatarStyle} src={photoUrl ?? ""} />;
    } else {
        const initials = displayName
            ?.split(" ")
            ?.filter((nameComponent) => nameComponent.length > 0)
            ?.map((nameComponent) => nameComponent[0])
            ?.join("");

        avatar = <Avatar sx={avatarStyle}>{initials}</Avatar>;
    }

    return (
        <Dialog open={isUserProfileDialogOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>Profil</DialogTitle>
            <DialogContent>
                <Grid container spacing={5} direction="column">
                    <Grid item container justifyContent="center" alignItems="center">
                        <Grid item>
                            {avatar}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField fullWidth label="Név" value={displayName} disabled />
                    </Grid>
                    <Grid item>
                        <TextField fullWidth label="E-mail-cím" value={email} disabled />
                    </Grid>
                    <Grid item>
                        <TextField fullWidth label="Telefonszám" placeholder="+36 20 123 4567" value={newPhoneNumber} onChange={handlePhoneNumberChange} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Bezár
                </Button>
                <LoadingButton
                    loading={isSaving}
                    disabled={phoneNumber === newPhoneNumber}
                    variant="contained"
                    onClick={handleSaveClick}
                >
                    Mentés
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}
