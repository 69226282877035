import * as React from "react";
import { styled } from "@mui/material/styles";

interface IProps {
    type: "terms of service" | "privacy policy";
}

export function StaticContent({ type }: IProps) {
    return (
        <StaticContentContainer>
            {type === "terms of service" ? (
                <div>
                    <h1>Terms of Service (&quot;Terms&quot;)</h1>
                </div>
            ) : null}

            {type === "privacy policy" ? (
                <div>
                    <h1>Privacy Policy</h1>
                </div>
            ) : null}
        </StaticContentContainer>
    );
}

// Styles
const StaticContentContainer = styled("div")`
    padding: 20px 40px;
`;
