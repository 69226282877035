import {
    Button, DialogActions, FormControl, FormGroup, FormLabel, Grid,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import { DateTimePicker, LoadingButton } from "@mui/lab";
import {
    IActivismEvent, IActivismEventCreate, IActivismEventUpdate, serializeActivismEvent,
} from "@resistance-tech/api";
import React, { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import { ActivismUserPicker } from "./activismUserPicker";
import { GoogleMapsAutocomplete } from "./googleMaps/googleMapsAutocomplete";
import { IPlace } from "./googleMaps/googleMaps";

const TITLE_LENGTH_MIN = 6;
const TITLE_LENGTH_MAX = 30;
const LOCATION_LENGTH_MIN = 6;
const DURATION_HOURS_MIN = 0.5;
const DURATION_HOURS_MAX = 24;
const MAX_CAPACITY_MIN = 1;

/** This is a version of events where every input field corresponds to a DEFINED string */
interface IActivismEventInProgress {
    title: string;
    description: string;
    startTime: string;
    durationHours: string;
    durationHoursNumber: number | undefined;
    location: string;
    address: string;
    lat: number | undefined;
    lng: number | undefined;
    googlePlaceId: string | undefined;
    maxCapacityType: "unlimited" | "limited";
    maxCapacityLimit: string;
    maxCapacityLimitNumber: number | undefined;
    coordinatorUserId: string;
}

const eventInProgressToEventCreate = (event: IActivismEventInProgress): IActivismEventCreate | undefined => {
    if (event.durationHoursNumber == null) {
        // This shouldn't happen
        return undefined;
    }
    let maxCapacity: IActivismEventCreate["maxCapacity"];
    if (event.maxCapacityType === "unlimited") {
        maxCapacity = { type: "unlimited" as const };
    } else if (event.maxCapacityLimitNumber == null) {
        // This shouldn't happen
        return undefined;
    } else {
        maxCapacity = { type: "limited" as const, limit: event.maxCapacityLimitNumber };
    }
    const eventCreateRequest: IActivismEventCreate = {
        title: event.title,
        description: event.description.trim() === "" ? null : event.description,
        startTime: event.startTime,
        durationMinutes: Math.round(event.durationHoursNumber * 60),
        location: event.location,
        address: event.address,
        lat: event.lat ?? null,
        lng: event.lng ?? null,
        googlePlaceId: event.googlePlaceId ?? null,
        coordinatorUserId: event.coordinatorUserId,
        maxCapacity,
    };
    return eventCreateRequest;
};

const eventToEventInProgress = (editedEvent: IActivismEvent): IActivismEventInProgress => {
    const serializedEditedEvent = serializeActivismEvent(editedEvent);
    const eventInProgress: IActivismEventInProgress = {
        ...serializedEditedEvent,
        description: serializedEditedEvent.description ?? "",
        durationHours: (serializedEditedEvent.durationMinutes / 60).toString(),
        durationHoursNumber: serializedEditedEvent.durationMinutes / 60,
        address: serializedEditedEvent.address ?? "",
        lat: serializedEditedEvent.lat ?? undefined,
        lng: serializedEditedEvent.lng ?? undefined,
        googlePlaceId: serializedEditedEvent.googlePlaceId ?? undefined,
        maxCapacityType: serializedEditedEvent.maxCapacity === null ? "unlimited" : "limited",
        maxCapacityLimit: serializedEditedEvent.maxCapacity?.toString() ?? "",
        maxCapacityLimitNumber: serializedEditedEvent.maxCapacity ?? undefined,
    };
    return eventInProgress;
};

export function EventEditor(props: {
    currentUserId: string;
    /** If set, then this is an editing panel; otherwise a creating panel. */
    editedEvent: IActivismEvent | undefined;
    prefillData: IActivismEvent | undefined;
    onClose: () => void;
    onCreate: (events: IActivismEventCreate) => Promise<void>;
    onUpdate: (events: IActivismEventUpdate) => Promise<void>;
    onDelete: () => Promise<void>;
}) {
    const {
        currentUserId, editedEvent, onClose, onCreate, onUpdate, onDelete, prefillData,
    } = props;
    const [isWorking, setIsWorking] = useState(false);
    const [event, setEvent] = useState<IActivismEventInProgress>({
        title: "",
        description: "",
        startTime: new Date().toISOString(),
        durationHours: "2",
        durationHoursNumber: 2,
        location: "",
        address: "",
        lat: undefined,
        lng: undefined,
        googlePlaceId: undefined,
        maxCapacityType: "limited",
        maxCapacityLimit: "2",
        maxCapacityLimitNumber: 2,
        coordinatorUserId: currentUserId,
    });
    const {
        title,
        description,
        startTime,
        durationHours,
        durationHoursNumber,
        location,
        maxCapacityType,
        maxCapacityLimit,
        maxCapacityLimitNumber,
        coordinatorUserId,
        address,
        lat,
        lng,
    } = event;
    const { enqueueSnackbar } = useSnackbar();

    // Default the event to the currently edited event
    useEffect(() => {
        if (editedEvent !== undefined) {
            setEvent(eventToEventInProgress(editedEvent));
        } else if (prefillData !== undefined) {
            setEvent(eventToEventInProgress(prefillData));
        }
    }, [prefillData, editedEvent, setEvent]);

    const handleCreateClick = useCallback(async () => {
        const eventCreateRequest = eventInProgressToEventCreate(event);
        if (eventCreateRequest === undefined) {
            return;
        }
        try {
            setIsWorking(true);
            await onCreate(eventCreateRequest);
            enqueueSnackbar("Sikerült az esemény létrehozása.", { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Hiba történt: kérjük próbáld újra!", { variant: "error" });
        } finally {
            setIsWorking(false);
        }
    }, [event, enqueueSnackbar, onCreate]);

    const handleUpdateClick = useCallback(async () => {
        if (editedEvent === undefined) {
            return;
        }
        const eventCreateRequest = eventInProgressToEventCreate(event);
        try {
            setIsWorking(true);
            await onUpdate({ id: editedEvent.id, ...eventCreateRequest });
            enqueueSnackbar("Sikerült az esemény frissítése.", { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Hiba történt: kérjük próbáld újra!", { variant: "error" });
        } finally {
            setIsWorking(false);
        }
    }, [event, editedEvent, enqueueSnackbar, onUpdate]);

    const handleDeleteClick = useCallback(async () => {
        try {
            setIsWorking(true);
            await onDelete();
            enqueueSnackbar("Sikerült az esemény törlése.", { variant: "success" });
        } catch (e) {
            console.error(e);
            enqueueSnackbar("Hiba történt: kérjük próbáld újra!", { variant: "error" });
        } finally {
            setIsWorking(false);
        }
    }, [enqueueSnackbar, onDelete]);

    const getTextValueChangeHandler = useCallback((key: "title" | "description" | "location" | "durationHours" | "maxCapacityType" | "maxCapacityLimit") => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEvent({
            ...event,
            [key]: e.target.value,
        });
    }, [event, setEvent]);
    const handleTitleChange = getTextValueChangeHandler("title");
    const handleDescriptionChange = getTextValueChangeHandler("description");
    const handleLocationChange = getTextValueChangeHandler("location");

    const handleDurationHoursChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newDurationHours = e.target.value;
        const newDurationHoursNumber = parseFloat(newDurationHours.replace(/,/g, "."));
        setEvent({
            ...event,
            durationHours: newDurationHours,
            durationHoursNumber: Number.isNaN(newDurationHoursNumber) ? undefined : newDurationHoursNumber,
        });
    }, [event, setEvent]);

    const handleMaxCapacityLimitChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newMaxCapacityLimit = e.target.value;
        const newMaxCapacityLimitNumber = parseInt(newMaxCapacityLimit, 10);
        setEvent({
            ...event,
            maxCapacityLimit: newMaxCapacityLimit,
            maxCapacityLimitNumber: Number.isNaN(newMaxCapacityLimitNumber) ? undefined : newMaxCapacityLimitNumber,
        });
    }, [event, setEvent]);

    const handleMaxCapacityTypeChange = useCallback((_e: React.MouseEvent<HTMLElement, MouseEvent>, value: "limited" | "unlimited") => {
        setEvent({
            ...event,
            maxCapacityType: value,
            maxCapacityLimit: "2",
            maxCapacityLimitNumber: 2,
        });
    }, [event, setEvent]);

    const handleStartTimeChange = useCallback((newDateTime: Date | null) => {
        if (newDateTime == null) {
            return;
        }
        setEvent({
            ...event,
            startTime: newDateTime.toISOString(),
        });
    }, [event, setEvent]);

    const handleCoordinatorUserIdChange = useCallback((newCoordinatorUserId: string | null) => {
        setEvent({
            ...event,
            coordinatorUserId: newCoordinatorUserId ?? currentUserId,
        });
    }, [event, setEvent, currentUserId]);

    const handleGoogleMapsPlaceChange = useCallback((newPlace: IPlace) => {
        setEvent({
            ...event,
            // location: newPlace.name,
            address: newPlace.address,
            lat: newPlace.lat,
            lng: newPlace.lng,
            googlePlaceId: newPlace.googlePlaceId,
        });
    }, [event, setEvent]);

    // Validation
    let titleError: string | undefined;
    if (title.length < TITLE_LENGTH_MIN) {
        titleError = `Olyan címet adj az eseménynek, amiből ránézésre kiderül, hogy miről van szó (min. ${TITLE_LENGTH_MIN} karakter)`;
    } else if (title.length > TITLE_LENGTH_MAX) {
        titleError = `Az esemény részleteit a leírásba tedd bele (max. ${TITLE_LENGTH_MAX} karakter)`;
    }
    let locationError: string | undefined;
    if (location.length < LOCATION_LENGTH_MIN) {
        locationError = `Adj meg egy megtalálható helyszínt az eseménynek  (min. ${LOCATION_LENGTH_MIN} karakter)`;
    }
    let durationHoursError: string | undefined;
    if (durationHours === "") {
        durationHoursError = "Ez a mező kötelező";
    } else if (/[0-9,]*/.exec(durationHours)?.[0] !== durationHours) {
        durationHoursError = "Az esemény hosszának számnak kell lennie";
    } else if (durationHoursNumber === undefined) {
        durationHoursError = "Az esemény hosszának számnak kell lennie";
    } else if (durationHoursNumber < DURATION_HOURS_MIN) {
        durationHoursError = `Az eseménynek legalább ${DURATION_HOURS_MIN} óra hosszúnak kell lennie`;
    } else if (durationHoursNumber > DURATION_HOURS_MAX) {
        durationHoursError = `Az esemény legfeljebb ${DURATION_HOURS_MAX} óra hosszú lehet - szervezz inkább több eseményt`;
    }
    let maxCapacityLimitError: string | undefined;
    if (maxCapacityType === "limited") {
        if (maxCapacityLimit === "") {
            maxCapacityLimitError = "Ez a mező kötelező";
        } else if (/[0-9]*/.exec(maxCapacityLimit)?.[0] !== maxCapacityLimit) {
            maxCapacityLimitError = "A maximális résztvevők számának egész számnak kell lennie";
        } else if (maxCapacityLimitNumber === undefined) {
            maxCapacityLimitError = "A maximális résztvevők számának egész számnak kell lennie";
        } else if (Math.round(maxCapacityLimitNumber) !== maxCapacityLimitNumber) {
            maxCapacityLimitError = "A maximális résztvevők számának egész számnak kell lennie";
        } else if (maxCapacityLimitNumber < MAX_CAPACITY_MIN) {
            maxCapacityLimitError = `A maximális résztvevők száma legalább ${MAX_CAPACITY_MIN} kell legyen`;
        }
    }
    let addressError: string | undefined;
    if ((address?.trim() ?? "") === "") {
        addressError = "Ez a mező kötelező - használd a térképes keresőt a helyszín beállításához!";
    }
    const isEverythingValid = titleError === undefined
        && locationError === undefined
        && durationHoursError === undefined
        && maxCapacityLimitError === undefined
        && addressError === undefined;

    return (
        <Grid container direction="column" spacing={5}>
            <Grid item>
                <TextField
                    required
                    label="Esemény címe"
                    variant="outlined"
                    value={title}
                    onChange={handleTitleChange}
                    error={titleError !== undefined}
                    helperText={titleError}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <TextField
                    label="Esemény leírása"
                    multiline
                    rows={4}
                    variant="outlined"
                    value={description}
                    onChange={handleDescriptionChange}
                    fullWidth
                    helperText="Tipp: írd le az esemény tevékenységét, célját, hogy hol lesz gyülekező, mit hozzanak magukkal az aktivisták, és ha van, akkor a Facebook esemény linkjét!"
                />
            </Grid>
            <Grid item>
                <DateTimePicker
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    renderInput={(fieldProps) => <TextField fullWidth {...fieldProps} />}
                    label="Esemény időpontja"
                    value={new Date(startTime)}
                    onChange={handleStartTimeChange}
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    label="Esemény hossza (órában)"
                    variant="outlined"
                    value={durationHours}
                    onChange={handleDurationHoursChange}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9.,]*" }}
                    error={durationHoursError !== undefined}
                    helperText={durationHoursError}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    label="Helyszín"
                    variant="outlined"
                    value={location}
                    onChange={handleLocationChange}
                    error={locationError !== undefined}
                    helperText={locationError}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <TextField
                    required
                    disabled
                    label="Cím"
                    variant="outlined"
                    value={address}
                    error={addressError !== undefined}
                    helperText={addressError ?? "Ez a mező nem szerkeszthető - a cím meghatározásához használd a térképes keresőt"}
                    fullWidth
                    // For reason, see: https://mui.com/components/text-fields/#shrink
                    InputLabelProps={{ shrink: address !== "" && address !== undefined }}
                />
            </Grid>
            <Grid item>
                <GoogleMapsAutocomplete
                    onChange={handleGoogleMapsPlaceChange}
                    value={(lat == null || lng == null) ? undefined : {
                        name: location,
                        lat,
                        lng,
                    }}
                />
            </Grid>
            <Grid item>
                <FormControl component="fieldset" variant="outlined">
                    <FormLabel component="legend">Maximum résztvevők száma</FormLabel>
                    <FormGroup>
                        <ToggleButtonGroup
                            color="standard"
                            value={maxCapacityType}
                            exclusive
                            onChange={handleMaxCapacityTypeChange}
                        >
                            <ToggleButton value="limited">
                                <PersonIcon />
                                {" "}
                                Korlátozott
                            </ToggleButton>
                            <ToggleButton value="unlimited">
                                <GroupsIcon />
                                {" "}
                                Korlátlan
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item sx={{ display: maxCapacityType === "unlimited" ? "none" : "block" }}>
                <TextField
                    required
                    disabled={maxCapacityType === "unlimited"}
                    variant="outlined"
                    label="Maximum résztvevők száma"
                    value={maxCapacityType === "limited" ? maxCapacityLimit : "2"}
                    onChange={handleMaxCapacityLimitChange}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    error={maxCapacityLimitError !== undefined}
                    helperText={maxCapacityLimitError}
                    fullWidth
                />
            </Grid>
            <Grid item>
                <ActivismUserPicker
                    userId={coordinatorUserId}
                    onChange={handleCoordinatorUserIdChange}
                    organiserOnly
                    inputProps={{
                        label: "Koordinátor",
                    }}
                />
            </Grid>
            <Grid item>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        disabled={isWorking}
                    >
                        Mégse
                    </Button>
                    {editedEvent !== undefined && (
                        <LoadingButton
                            variant="contained"
                            color="error"
                            onClick={handleDeleteClick}
                            loading={isWorking}
                            startIcon={<DeleteIcon />}
                        >
                            Törlés
                        </LoadingButton>
                    )}
                    {editedEvent !== undefined && (
                        <LoadingButton
                            variant="contained"
                            color="success"
                            onClick={handleUpdateClick}
                            loading={isWorking}
                            disabled={!isEverythingValid}
                            startIcon={<CheckIcon />}
                        >
                            Mentés
                        </LoadingButton>
                    )}
                    {editedEvent === undefined && (
                        <LoadingButton
                            variant="contained"
                            color="success"
                            onClick={handleCreateClick}
                            loading={isWorking}
                            disabled={!isEverythingValid}
                            startIcon={<AddIcon />}
                        >
                            Létrehozás
                        </LoadingButton>
                    )}
                </DialogActions>
            </Grid>
        </Grid>
    );
}
