import {
    Box, Card, Grid, Theme, Typography,
} from "@mui/material";
import { IActivismEvent, assertNever } from "@resistance-tech/api";
import React, { useCallback, useContext } from "react";
import PeopleIcon from "@mui/icons-material/People";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import {
    EventState, getEventCapacityString, getEventState, getEventTimeRangeString,
} from "./utils";
import { ComponentStateContext } from "./activism.state";
import { ActivismState } from "./activism.types";
import { BadgeIcon } from "./badgeIcon";

const pastEventBackgroundColorGetter = (theme: Theme) => theme.palette.action.disabledBackground;
const currentUserEventBackgroundColorGetter = (theme: Theme) => theme.palette.primary.main;
const fullEventBackgroundColorGetter = (theme: Theme) => theme.palette.action.disabledBackground;
const bookableEventBackgroundColorGetter = (theme: Theme) => theme.palette.grey[100];

const pastEventFontColorGetter = (theme: Theme) => theme.palette.text.disabled;
const currentUserEventFontColorGetter = (theme: Theme) => theme.palette.primary.contrastText;
const fullEventFontColorGetter = (theme: Theme) => theme.palette.text.disabled;
const bookableEventFontColorGetter = (theme: Theme) => theme.palette.text.primary;

const pastEventBorderColorGetter = (theme: Theme) => theme.palette.grey[500];
const currentUserEventBorderColorGetter = (theme: Theme) => theme.palette.primary.dark;
const fullEventBorderColorGetter = (theme: Theme) => theme.palette.grey[500];
const bookableEventBorderColorGetter = (theme: Theme) => theme.palette.grey[500];

export function EventChip(props: { event: IActivismEvent, currentUserId: string }) {
    const { dispatch } = useContext(ComponentStateContext);
    const { event, currentUserId } = props;
    const {
        title, id, location, coordinatorUserId, creatorUserId,
    } = event;
    const eventState = getEventState(event, currentUserId);
    let backgroundColorGetter: (theme: Theme) => string;
    let fontColorGetter: (theme: Theme) => string;
    let borderColorGetter: (theme: Theme) => string;
    switch (eventState) {
    case EventState.PAST:
        backgroundColorGetter = pastEventBackgroundColorGetter;
        fontColorGetter = pastEventFontColorGetter;
        borderColorGetter = pastEventBorderColorGetter;
        break;
    case EventState.PARTICIPATING:
        backgroundColorGetter = currentUserEventBackgroundColorGetter;
        fontColorGetter = currentUserEventFontColorGetter;
        borderColorGetter = currentUserEventBorderColorGetter;
        break;
    case EventState.FULL:
        backgroundColorGetter = fullEventBackgroundColorGetter;
        fontColorGetter = fullEventFontColorGetter;
        borderColorGetter = fullEventBorderColorGetter;
        break;
    case EventState.BOOKABLE:
        backgroundColorGetter = bookableEventBackgroundColorGetter;
        fontColorGetter = bookableEventFontColorGetter;
        borderColorGetter = bookableEventBorderColorGetter;
        break;
    default:
        assertNever(eventState, `Unexpected event state "${eventState}"`);
        backgroundColorGetter = bookableEventBackgroundColorGetter;
        fontColorGetter = bookableEventFontColorGetter;
        borderColorGetter = bookableEventBorderColorGetter;
    }
    const fontSize = "12px";
    const isCreator = creatorUserId === currentUserId;
    const isCoordinator = coordinatorUserId === currentUserId;

    const handleClick = useCallback(() => {
        dispatch({ type: ActivismState.ACTIVISM_OPEN_EVENT_VIEWER, payload: { eventId: id } });
    }, [dispatch, id]);

    return (
        <Card
            variant="outlined"
            sx={{
                width: "100%", backgroundColor: backgroundColorGetter, borderWidth: "1px 1px 1px 5px", borderColor: borderColorGetter, cursor: "pointer",
            }}
            onClick={handleClick}
        >
            <Box sx={{ padding: "5px" }}>
                <Typography variant="body2" sx={{ fontWeight: "bold", fontSize, color: fontColorGetter }}>
                    {isCreator && (
                        <>
                            <BadgeIcon tooltipTitle="Szervező vagy" IconComponent={AdminPanelSettingsIcon} />
                            {" "}
                        </>
                    )}
                    {isCoordinator && (
                        <>
                            <BadgeIcon tooltipTitle="Koordinátor vagy" IconComponent={SupervisorAccountIcon} />
                            {" "}
                        </>
                    )}
                    {title}
                </Typography>
                <Grid container rowSpacing={0} columnSpacing={2}>
                    <Grid item>
                        <Typography variant="body1" sx={{ fontSize, color: fontColorGetter }}>
                            <AccessTimeIcon sx={{ fontSize: "inherit" }} />
                            {" "}
                            {getEventTimeRangeString(event)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ fontSize, color: fontColorGetter }}>
                            <LocationOnIcon sx={{ fontSize: "inherit" }} />
                            {" "}
                            {location}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" sx={{ fontSize, color: fontColorGetter }}>
                            <PeopleIcon sx={{ fontSize: "inherit" }} />
                            {" "}
                            {getEventCapacityString(event)}
                            {eventState === EventState.PARTICIPATING && (
                                <>
                                    {" "}
                                    (résztvevő vagy)
                                </>
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
}
