import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import {
    IconButton, InputAdornment, TextField, TextFieldProps,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import MapIcon from "@mui/icons-material/Map";
import { IPlace } from "./googleMaps";
import "./googleMaps.css";

export function GoogleMapsAutocompleteInput(props: {
    onChange: (place: IPlace | undefined) => void;
    initialSearchFieldValue?: string;
    textFieldProps?: {
        label?: TextFieldProps["label"];
        size?: TextFieldProps["size"];
        placeholder?: TextFieldProps["placeholder"];
    }
}) {
    const { onChange, initialSearchFieldValue, textFieldProps } = props;
    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete | undefined>(undefined);
    const [searchFieldValue, setSearchFieldValue] = useState<string>(initialSearchFieldValue ?? "");

    const handleLoad = (newAutocomplete: google.maps.places.Autocomplete) => {
        setAutocomplete(newAutocomplete);
    };

    const handlePlaceChanged = () => {
        const placeResult = autocomplete?.getPlace();
        if (placeResult === undefined) {
            return;
        }
        const {
            name, geometry, place_id: googlePlaceId, formatted_address: address,
        } = placeResult;
        const lat = geometry?.location?.lat();
        const lng = geometry?.location?.lng();
        if (googlePlaceId === undefined
            || name === undefined
            || lat === undefined
            || lng === undefined
            || address === undefined
        ) {
            return;
        }
        setSearchFieldValue(name);
        onChange({
            googlePlaceId,
            name,
            address,
            lat,
            lng,
        });
    };

    const handleSearchFieldChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchFieldValue(e.target.value);
    };

    const handleClearInput = () => {
        setSearchFieldValue("");
        onChange(undefined);
    };

    return (
        <Autocomplete
            onLoad={handleLoad}
            onPlaceChanged={handlePlaceChanged}
            fields={["formatted_address", "name", "geometry.location", "place_id"]}
        >
            <TextField
                label={textFieldProps?.label ?? "Cím keresése"}
                placeholder={textFieldProps?.placeholder ?? "Cím"}
                size={textFieldProps?.size}
                variant="outlined"
                value={searchFieldValue}
                onChange={handleSearchFieldChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <MapIcon />
                        </InputAdornment>
                    ),
                    endAdornment: searchFieldValue === "" ? undefined : (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClearInput}
                                edge="end"
                            >
                                <ClearIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Autocomplete>
    );
}
