import React, {
    useCallback, useEffect, useState,
} from "react";
import {
    FirestoreAdminUserRole, IUserInfo, IUserRolesResponse, ROLE_DISPLAY_NAMES,
} from "@resistance-tech/api";
import {
    Checkbox, Typography,
} from "@mui/material";

import MaterialTable from "@material-table/core";
import { getGlobalServices } from "../../../services/services";

import "./rolesWidget.scss";
import { InvitationDialog } from "./invitationDialog";

type UpdateRoles = (userInfo: IUserInfo, action: "add" | "remove", role: string) => void;

export function RolesWidget() {
    const [userList, setUserList] = useState<IUserRolesResponse>({ users: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [updateList, setUpdateList] = useState<string[]>([]);
    const [isDialogActive, setDialogActive] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const { users } = userList;

    useEffect(() => {
        let mounted = true;
        setIsLoading(true);
        getGlobalServices()?.functionsService
            .getUserRoles()
            .then((ul) => {
                if (!mounted) {
                    return;
                }
                setUserList(ul);
                setIsLoading(false);
            });
        return () => { mounted = false; };
    }, []);

    const updateRoles: UpdateRoles = useCallback((userInfo, action, role): void => {
        const updatedUserInfo = { ...userInfo };
        if (action === "add") {
            updatedUserInfo.roles.push(role);
        }

        if (action === "remove") {
            const roleIndex = updatedUserInfo.roles.indexOf(role);
            if (roleIndex > -1) {
                updatedUserInfo.roles.splice(roleIndex, 1);
            }
        }

        if (!updateList.includes(userInfo.id)) {
            setUpdateList([...updateList, userInfo.id]);
        }

        const updatedUsers = [...users];
        const userIndex = users.indexOf(userInfo);
        updatedUsers[userIndex] = updatedUserInfo;
        setUserList({ users: updatedUsers });
    }, [updateList, users]);

    const save = useCallback(() => {
        setIsSaving(true);
        const roleUpdates = users
            .filter((user) => updateList.includes(user.id))
            .map((user) => ({ email: user.email, updatedRoles: user.roles }));
        getGlobalServices()?.functionsService
            .updateUserRoles(roleUpdates)
            .finally(() => {
                setUpdateList([]);
                setIsSaving(false);
            });
    }, [updateList, users]);

    const isUpdated = useCallback((userInfo: IUserInfo): boolean => updateList.includes(userInfo.id), [updateList]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>, userInfo: IUserInfo) => {
        const { name: role, checked } = event.target;
        updateRoles(userInfo, checked ? "add" : "remove", role);
    }, [updateRoles]);

    const handleDialogClose = useCallback(() => {
        setIsLoading(true);
        getGlobalServices()?.functionsService
            .getUserRoles()
            .then((ul) => {
                setUserList(ul);
                setIsLoading(false);
            });
        setDialogActive(false);
    }, []);

    const renderMaybeModifiedText = useCallback((userRow: IUserInfo, value: string | undefined) => (
        <Typography variant="body2" color={isUpdated(userRow) ? "secondary" : "inherit"}>{value ?? ""}</Typography>
    ), [isUpdated]);

    const roleColumns = Object.values(FirestoreAdminUserRole).map((role) => {
        const roleDisplayName = ROLE_DISPLAY_NAMES[role];
        return {
            title: roleDisplayName,
            field: "isAdmin",
            render: (userRow: IUserInfo) => (
                <Checkbox
                    color={isUpdated(userRow) ? "secondary" : "default"}
                    name={role}
                    checked={userRow.roles.includes(role)}
                    onChange={(e) => handleChange(e, userRow)}
                />
            ),
        };
    });

    return (
        <>
            <MaterialTable
                title="Jogosultságok"
                options={{
                    paging: true,
                    pageSize: 10,
                }}
                isLoading={isLoading || isSaving}
                columns={[
                    { title: "Név", field: "displayName", render: (userRow) => renderMaybeModifiedText(userRow, userRow.displayName) },
                    {
                        title: "E-mail", field: "email", defaultSort: "asc", render: (userRow) => renderMaybeModifiedText(userRow, userRow.email),
                    },
                    ...roleColumns,
                    { title: "Létrehozás időpontja", field: "creationTime", render: (userRow) => renderMaybeModifiedText(userRow, new Date(userRow.creationTime).toLocaleString("HU-hu")) },
                    { title: "Legutóbbi belépés időpontja", field: "lastSignInTime", render: (userRow) => renderMaybeModifiedText(userRow, ((userRow.lastSignInTime ?? "") === "" ? "" : new Date(userRow.lastSignInTime ?? "").toLocaleString("HU-hu"))) },
                ]}
                actions={[
                    {
                        icon: "save",
                        tooltip: "Módosítások mentése",
                        isFreeAction: true,
                        onClick: save,
                        disabled: updateList.length === 0,
                    },
                    {
                        icon: "add",
                        tooltip: "Új felhasználó",
                        isFreeAction: true,
                        onClick: () => {
                            setDialogActive(true);
                        },
                    },
                ]}
                data={users}
            />
            <InvitationDialog isActive={isDialogActive} onClose={handleDialogClose} />
        </>
    );
}
