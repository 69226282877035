import {
    Card, Grid, Typography,
} from "@mui/material";
import React from "react";

export function ActivismStatCard(props: { icon: React.ReactNode; value: number; label: string }) {
    const { icon, value, label } = props;
    return (
        <Card variant="outlined" sx={{ padding: (theme) => theme.spacing(3) }}>
            <Grid container spacing={3} direction="row" justifyContent="stretch" flexWrap="nowrap">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item container spacing={0} direction="column" justifyContent="stretch">
                    <Grid item>
                        <Typography variant="h5" sx={{ fontWeight: "bold" }} paragraph={false}>
                            {value}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" paragraph={false}>
                            {label}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
}
