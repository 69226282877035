import { StoreEnhancer, createStore, loggingMiddleware } from "redoodle";
import { applyMiddleware } from "redux";
import { appReducer } from "./reducers";
import { IAppState } from "./state";

const initialState: IAppState = {
    currentUser: undefined,
    currentUserAdminUserConfig: undefined,
    sheetPreferences: undefined,
    hasPendingWrites: false,
    posts: {},
    senders: {},
    surveys: {},
    events: {},
    tips: {},
    jobs: {},
    isUserProfileDialogOpen: false,
};

export function createAppStore() {
    const middlewareEnhancer = applyMiddleware(loggingMiddleware()) as unknown as StoreEnhancer;
    return createStore<IAppState>(appReducer, initialState, middlewareEnhancer);
}
