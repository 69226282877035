import { User } from "firebase/auth";
import { sortBy, toPairs } from "lodash-es";
import { createSelector } from "reselect";
import { IAppState } from "./state";

export const selectHasPendingWrites = (state: IAppState) => state.hasPendingWrites;

export const selectIsUserProfileDialogOpen = (state: IAppState) => state.isUserProfileDialogOpen;

export const selectCurrentUser = (state: IAppState) => state.currentUser;

export const selectCurrentUserId = createSelector(
    selectCurrentUser,
    (user: User | undefined) => (user === undefined ? undefined : user.uid),
);

export const selectPosts = (state: IAppState) => sortBy(
    toPairs(state.posts),
    ([, post]) => -post.createdTimestamp.toMillis(),
);
export const selectPost = (state: IAppState, postId: string) => state.posts[postId];

export const selectSenders = (state: IAppState) => state.senders;
export const selectSender = (state: IAppState, postId: string) => state.senders[postId];

export const selectSurveys = (state: IAppState) => sortBy(
    toPairs(state.surveys),
    ([, survey]) => -survey.createdTimestamp.toMillis(),
);
export const selectSurvey = (state: IAppState, surveyId: string) => state.surveys[surveyId];

export const selectEvents = (state: IAppState) => sortBy(
    toPairs(state.events),
    ([, event]) => -event.date.toMillis(),
);

export const selectEvent = (state: IAppState, eventId: string) => state.events[eventId];

export const selectTips = (state: IAppState) => sortBy(
    toPairs(state.tips),
    ([, tip]) => -tip.createdTimestamp.toMillis(),
);

export const selectTip = (state: IAppState, tipId: string) => state.tips[tipId];

export const selectJobs = (state: IAppState) => state.jobs;

export const selectCurrentUserAdminUserConfig = (
    state: IAppState,
) => state.currentUserAdminUserConfig;
