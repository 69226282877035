import {
    Autocomplete, TextField, TextFieldProps,
} from "@mui/material";
import { IActivismUserProfile } from "@resistance-tech/api";
import React, {
    useCallback, useContext, useEffect, useMemo, useState,
} from "react";
import { ComponentStateContext } from "./activism.state";
import { ActivismUserBadge } from "./activismUserBadge";

export function ActivismUserPicker(props: {
    onChange: (userId: string | null) => void;
    userId: string | null;
    /** @default false */
    organiserOnly?: boolean;
    inputProps?: {
        label?: TextFieldProps["label"];
        placeholder?: TextFieldProps["placeholder"];
        disabled?: boolean;
        size?: TextFieldProps["size"];
    }
}) {
    const {
        onChange, userId, organiserOnly, inputProps,
    } = props;
    const [value, setValue] = useState<IActivismUserProfile | null>(null);
    const { state } = useContext(ComponentStateContext);
    const userProfiles = organiserOnly ? state.organiserUserProfiles : state.userProfiles;
    const userProfilesSorted = useMemo(() => userProfiles.sort((a, b) => (a.displayName ?? "").localeCompare((b.displayName ?? ""))), [userProfiles]);

    useEffect(() => {
        const newValue = userId === null
            ? null
            : (userProfiles.find((user) => user.id === userId) ?? null);
        setValue(newValue);
    }, [userId, userProfiles, setValue]);

    const handleChange = useCallback((
        _event: React.SyntheticEvent<Element, Event>,
        newValue: IActivismUserProfile | null,
    ) => {
        onChange(newValue?.id ?? null);
    }, [onChange]);

    return (
        <Autocomplete<IActivismUserProfile>
            options={userProfilesSorted}
            value={value}
            onChange={handleChange}
            disabled={userProfiles.length === 0 || inputProps?.disabled}
            getOptionLabel={(option) => option.displayName ?? "(nincs név)"}
            renderInput={(params) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    label={inputProps?.label}
                    placeholder={inputProps?.placeholder}
                    size={inputProps?.size}
                    helperText={userProfiles.length === 0 ? "Még nem töltődtek be a felhasználók..." : undefined}
                />
            )}
            renderOption={(params, option, { selected }) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...params} key={option.id}>
                    <ActivismUserBadge userProfile={option} isSelected={selected} />
                </li>
            )}
            renderTags={(tags, getTagProps) => tags.map((tag, index) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <span {...getTagProps({ index })} key={tag.id}>
                    <ActivismUserBadge userProfile={tag} />
                </span>
            ))}
        />
    );
}
