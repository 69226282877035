import {
    Avatar, Box, Grid, Theme,
} from "@mui/material";
import { IActivismUserProfile } from "@resistance-tech/api";
import React from "react";
import EventIcon from "@mui/icons-material/Event";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export function ActivismUserCard(props: { userProfile: IActivismUserProfile }) {
    const { userProfile } = props;
    const {
        displayName, photoURL, email, phoneNumber, stats,
    } = userProfile;
    return (
        <Box
            sx={{
                fontSize: "12px",
                fontWeight: "normal",
                padding: (theme: Theme) => theme.spacing(2),
            }}
        >
            <Grid container spacing={2} direction="row" flexWrap="nowrap" alignItems="center">
                <Grid item flexShrink={1}>
                    <Avatar alt={displayName} src={photoURL} />
                </Grid>
                <Grid item container spacing={0} direction="column" alignItems="flex-start" flexShrink={1}>
                    <Grid item>
                        <strong>{displayName ?? "(nincs név)"}</strong>
                    </Grid>
                    {phoneNumber && (
                        <Grid item>
                            {phoneNumber ?? ""}
                        </Grid>
                    )}
                    {email && (
                        <Grid item>
                            {email ?? ""}
                        </Grid>
                    )}
                    {stats && (
                        <Grid item container columnSpacing={3} rowSpacing={0} direction="row" justifyContent="flex-start" alignItems="baseline">
                            <Grid item>
                                <EventIcon sx={{ fontSize: "11px" }} />
                                {" "}
                                {stats.numberOfEventsParticipating}
                                {" "}
                                esemény
                            </Grid>
                            <Grid item>
                                <TimelapseIcon sx={{ fontSize: "11px" }} />
                                {" "}
                                {stats.hoursOfEventsParticipating}
                                {" "}
                                óra
                            </Grid>
                            <Grid item>
                                <SupervisorAccountIcon sx={{ fontSize: "11px" }} />
                                {" "}
                                {stats.numberOfEventsCoordinating}
                                {" "}
                                koordinálás
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
}
